/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PointFactorRegister } from '../models/point-factor-register';
import { ProductModel } from '../models/product-model';
import { StoreModel } from '../models/store-model';
import { CityModel } from '../models/city-model';
import { Participant } from '../models/participant';
import { PermissionGroup } from '../models/permission-group';
import { Permission } from '../models/permission';
import { PermissionGroupRequest } from '../models/permission-group-request';
import { StateModel } from '../models/state-model';
import { Account } from '../models/account';
import { User } from '../models/user';
import { ConsolidatedAccount } from '../models/consolidated-account';
import { PointFactorPromotion } from '../models/point-factor-promotion';
import { RescuePromotion } from '../models/rescue-promotion';
import { ReportParticipant } from '../models/report-participant';
import { FilterParticipant } from '../models/filter-participant';
import { FilterCupom } from '../models/filter-cupom';
import { Voucher } from '../models/voucher';
import { FilterVoucher } from '../models/filter-voucher';
import { PdvPromotion } from '../models/pdv-promotion';
import { FilterLoja } from '../models/filter-loja';
import { PointFactorCoffeeRegister } from '../models/point-factor-coffee-register';
import { ConsolidatedAccountCoffee } from '../models/consolidated-account-coffee';
import { PointFactorCoffeeUpdate } from '../models/point-factor-coffee-update';
import { PromotionCoffeeStore } from '../../api/models/point-factor-coffee-store';
import { ClusterConfiguration } from '../models/cluster-configuration';
import { EcommercePromotion } from '../models/ecommerce-promotion';
import { EcommercePromotionCpfFile } from '../models/ecommerce-promotion-cpf-file';
import { PdvPromotionCpfFile } from '../models/pdv-promotion-cpf-file';

@Injectable({
  providedIn: 'root',
})
class BackofficeService extends __BaseService {
  static readonly GetPath = '/api/Backoffice/GetPointFactor';
  static readonly UpdatePath = '/api/Backoffice/UpdatePointFactor';
  static readonly GetPathCoffee = '/api/Backoffice/GetPointFactorCoffee';
  static readonly UpdatePathCoffee = '/api/Backoffice/UpdatePointFactorCoffee';
  static readonly GetProductsPath = '/api/Backoffice/GetProducts';
  static readonly InsertProductsPath = '/api/Backoffice/InsertProducts';
  static readonly UpdateProductsPath = '/api/Backoffice/UpdateProducts';
  static readonly GetStoresPath = '/api/Backoffice/GetStores';
  static readonly InsertStoresPath = '/api/Backoffice/InsertStores';
  static readonly UpdateStorePath = '/api/Backoffice/UpdateStore';
  static readonly GetCitiesPath = '/api/Backoffice/GetCities';
  static readonly GetParticipantByNamePath = '/api/Backoffice/GetParticipantByName';
  static readonly GetPermissionsGroupPath = '/api/Backoffice/GetPermissionsGroup';
  static readonly GetPermissionsPath = '/api/Backoffice/GetPermissions';
  static readonly InsertPermissionsPath = '/api/Backoffice/InsertPermissionsGroup';
  static readonly UpdatePermissionGroupPath = '/api/Backoffice/UpdatePermissionGroup';
  static readonly UserPermissionPath = '/api/Backoffice/UserPermission';
  static readonly InsertAccountPath = '/api/Backoffice/InsertAccount';
  static readonly DeleteAccountPath = '/api/Backoffice/DeleteAccount';
  static readonly GetStatePath = '/api/Backoffice/GetState';
  static readonly GetUsersPath = '/api/Backoffice/GetUsers';
  static readonly InsUsersPath = '/api/Backoffice/InsUsers';
  static readonly GetStatementPath = '/api/Backoffice/GetStatement';
  static readonly GetParticipantPath = '/api/Backoffice/GetParticipant';
  static readonly UpUsersPath = '/api/Backoffice/UpUsers';
  static readonly ChangePassUsersPath = '/api/Backoffice/ChangePassUsers';
  static readonly UpdatePdvPromotionPath = '/api/Backoffice/UpdatePdvPromotion';
  static readonly InsertPdvPromotionPath = '/api/Backoffice/InsertPdvPromotion';
  static readonly GetPdvPromotionPath = '/api/Backoffice/GetPdvPromotion';
  static readonly UpdatePointFactorPromotionPath = '/api/Backoffice/UpdatePointFactorPromotion';
  static readonly InsertPointFactorPromotionPath = '/api/Backoffice/InsertPointFactorPromotion';
  static readonly ValidatorPointFactorPromotionPath = '/api/Backoffice/ValidatorPointFactorPromotion';
  static readonly GetPointFactorPromotionPath = '/api/Backoffice/GetPointFactorPromotion';
  static readonly GetRescuePromotionPath = '/api/Backoffice/GetRescuePromotion';
  static readonly UpdateRescuePromotionPath = '/api/Backoffice/UpdateRescuePromotion';
  static readonly InsertRescuePromotionPath = '/api/Backoffice/InsertRescuePromotion';
  static readonly ValidatorRescuePromotionPath = '/api/Backoffice/ValidatorRescuePromotion';
  static readonly ReportParticipantsPath = '/api/Backoffice/ReportsParticipants';
  static readonly GetVoucherPath = '/api/Backoffice/GetVoucher';
  static readonly SaveVoucherPath = '/api/Backoffice/SaveVoucher';
  static readonly UpdateParticipantPath = '/api/Backoffice/UpdateParticipant';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetResponse(): __Observable<__StrictHttpResponse<PointFactorRegister>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetPointFactor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PointFactorRegister>;
      })
    );
  }
  /**
   * @return Success
   */
  Get(): __Observable<PointFactorRegister> {
    return this.GetResponse().pipe(
      __map(_r => _r.body as PointFactorRegister)
    );
  }

  /**
   * @param obj undefined
   */
  UpdateResponse(obj?: PointFactorRegister): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Backoffice/UpdatePointFactor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  Update(obj?: PointFactorRegister): __Observable<null> {
    return this.UpdateResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  GetCoffeResponse(): __Observable<__StrictHttpResponse<PointFactorCoffeeRegister>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetPointFactorCoffee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });
    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PointFactorCoffeeRegister>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCoffe(): __Observable<PointFactorCoffeeRegister> {
    return this.GetCoffeResponse().pipe(
      __map(_r => _r.body as PointFactorCoffeeRegister)
    );
  }

  /**
   * @param obj undefined
   */
  UpdateCoffeResponse(id?: number, obj?: PointFactorCoffeeUpdate): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    __body = obj;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Backoffice/UpdatePointFactorCoffee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  UpdateCoffe(id?: number, obj?: PointFactorCoffeeUpdate): __Observable<null> {
    return this.UpdateCoffeResponse(id, obj).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  GetProductsResponse(): __Observable<__StrictHttpResponse<Array<ProductModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetProducts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetProducts(): __Observable<Array<ProductModel>> {
    return this.GetProductsResponse().pipe(
      __map(_r => _r.body as Array<ProductModel>)
    );
  }

  /**
   * @param productModel undefined
   * @return Success
   */
  InsertProductsResponse(productModel?: ProductModel): __Observable<__StrictHttpResponse<Array<ProductModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = productModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/InsertProducts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductModel>>;
      })
    );
  }
  /**
   * @param productModel undefined
   * @return Success
   */
  InsertProducts(productModel?: ProductModel): __Observable<Array<ProductModel>> {
    return this.InsertProductsResponse(productModel).pipe(
      __map(_r => _r.body as Array<ProductModel>)
    );
  }

  /**
   * @param productModel undefined
   * @return Success
   */
  UpdateProductsResponse(productModel?: ProductModel): __Observable<__StrictHttpResponse<Array<ProductModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = productModel;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Backoffice/UpdateProducts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductModel>>;
      })
    );
  }
  /**
   * @param productModel undefined
   * @return Success
   */
  UpdateProducts(productModel?: ProductModel): __Observable<Array<ProductModel>> {
    return this.UpdateProductsResponse(productModel).pipe(
      __map(_r => _r.body as Array<ProductModel>)
    );
  }

  /**
   * @return Success
   */
  GetStoresResponse(): __Observable<__StrictHttpResponse<Array<StoreModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetStores`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StoreModel>>;
      })
    );
  }


  /**
   * @return Success
   */
  GetStores(): __Observable<Array<StoreModel>> {
    return this.GetStoresResponse().pipe(
      __map(_r => _r.body as Array<StoreModel>)
    );
  }

  GetStoresCoffeeResponse(): __Observable<__StrictHttpResponse<Array<PromotionCoffeeStore>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetStoresCoffee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PromotionCoffeeStore>>;
      })
    );
  }

  GetStoresCoffee(): __Observable<Array<PromotionCoffeeStore>> {
    return this.GetStoresCoffeeResponse().pipe(
      __map(_r => _r.body as Array<PromotionCoffeeStore>)
    );
  }

  /**
   * @param storeModel undefined
   * @return Success
   */
  InsertStoresResponse(storeModel?: StoreModel): __Observable<__StrictHttpResponse<Array<ProductModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = storeModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/InsertStores`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductModel>>;
      })
    );
  }
  /**
   * @param storeModel undefined
   * @return Success
   */
  InsertStores(storeModel?: StoreModel): __Observable<Array<ProductModel>> {
    return this.InsertStoresResponse(storeModel).pipe(
      __map(_r => _r.body as Array<ProductModel>)
    );
  }

  /**
   * @param storeModel undefined
   * @return Success
   */
  UpdateStoreResponse(storeModel?: StoreModel): __Observable<__StrictHttpResponse<Array<ProductModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = storeModel;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Backoffice/UpdateStore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductModel>>;
      })
    );
  }
  /**
   * @param storeModel undefined
   * @return Success
   */
  UpdateStore(storeModel?: StoreModel): __Observable<Array<ProductModel>> {
    return this.UpdateStoreResponse(storeModel).pipe(
      __map(_r => _r.body as Array<ProductModel>)
    );
  }

  /**
   * @param stateId undefined
   * @return Success
   */
  GetCitiesResponse(stateId?: string): __Observable<__StrictHttpResponse<Array<CityModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (stateId != null) __params = __params.set('stateId', stateId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetCities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CityModel>>;
      })
    );
  }
  /**
   * @param stateId undefined
   * @return Success
   */
  GetCities(stateId?: string): __Observable<Array<CityModel>> {
    return this.GetCitiesResponse(stateId).pipe(
      __map(_r => _r.body as Array<CityModel>)
    );
  }

  /**
   * @param name undefined
   * @return Success
   */
  GetParticipantByNameResponse(name?: string): __Observable<__StrictHttpResponse<Array<Participant>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (name != null) __params = __params.set('name', name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetParticipantByName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Participant>>;
      })
    );
  }
  /**
   * @param name undefined
   * @return Success
   */
  GetParticipantByName(name?: string): __Observable<Array<Participant>> {
    return this.GetParticipantByNameResponse(name).pipe(
      __map(_r => _r.body as Array<Participant>)
    );
  }

  /**
   * @return Success
   */
  GetPermissionsGroupResponse(): __Observable<__StrictHttpResponse<Array<PermissionGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetPermissionsGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PermissionGroup>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPermissionsGroup(): __Observable<Array<PermissionGroup>> {
    return this.GetPermissionsGroupResponse().pipe(
      __map(_r => _r.body as Array<PermissionGroup>)
    );
  }

  /**
   * @return Success
   */
  GetPermissionsResponse(): __Observable<__StrictHttpResponse<Array<Permission>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetPermissions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Permission>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPermissions(): __Observable<Array<Permission>> {
    return this.GetPermissionsResponse().pipe(
      __map(_r => _r.body as Array<Permission>)
    );
  }

  /**
   * @param group undefined
   */
  InsertPermissionsResponse(group?: PermissionGroupRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = group;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/InsertPermissionsGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param group undefined
   */
  InsertPermissions(group?: PermissionGroupRequest): __Observable<null> {
    return this.InsertPermissionsResponse(group).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param group undefined
   * @return Success
   */
  UpdatePermissionGroupResponse(group?: PermissionGroupRequest): __Observable<__StrictHttpResponse<Array<PermissionGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = group;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Backoffice/UpdatePermissionGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PermissionGroup>>;
      })
    );
  }
  /**
   * @param group undefined
   * @return Success
   */
  UpdatePermissionGroup(group?: PermissionGroupRequest): __Observable<Array<PermissionGroup>> {
    return this.UpdatePermissionGroupResponse(group).pipe(
      __map(_r => _r.body as Array<PermissionGroup>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  UserPermissionResponse(id?: number): __Observable<__StrictHttpResponse<Array<PermissionGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/UserPermission`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PermissionGroup>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  UserPermission(id?: number): __Observable<Array<PermissionGroup>> {
    return this.UserPermissionResponse(id).pipe(
      __map(_r => _r.body as Array<PermissionGroup>)
    );
  }

  /**
   * @param account undefined
   * @return Success
   */
  InsertAccountResponse(account?: Account): __Observable<__StrictHttpResponse<Array<StateModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = account;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/InsertAccount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StateModel>>;
      })
    );
  }
  /**
   * @param account undefined
   * @return Success
   */
  InsertAccount(account?: Account): __Observable<Array<StateModel>> {
    return this.InsertAccountResponse(account).pipe(
      __map(_r => _r.body as Array<StateModel>)
    );
  }

  /**
   * @param account undefined
   * @return Success
   */
  InsertAccountCoffeeResponse(account?: Account): __Observable<__StrictHttpResponse<Array<StateModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = account;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/InsertAccountCoffee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StateModel>>;
      })
    );
  }
  /**
   * @param account undefined
   * @return Success
   */
  InsertAccountCoffee(account?: Account): __Observable<Array<StateModel>> {
    return this.InsertAccountCoffeeResponse(account).pipe(
      __map(_r => _r.body as Array<StateModel>)
    );
  }

  /**
   * @param account undefined
   * @return Success
   */
  DeleteAccountResponse(account?: Account): __Observable<__StrictHttpResponse<Array<StateModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = account;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/DeleteAccount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StateModel>>;
      })
    );
  }
  /**
   * @param account undefined
   * @return Success
   */
  DeleteAccount(account?: Account): __Observable<Array<StateModel>> {
    return this.DeleteAccountResponse(account).pipe(
      __map(_r => _r.body as Array<StateModel>)
    );
  }

  /**
   * @return Success
   */
  GetStateResponse(): __Observable<__StrictHttpResponse<Array<StateModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetState`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StateModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetState(): __Observable<Array<StateModel>> {
    return this.GetStateResponse().pipe(
      __map(_r => _r.body as Array<StateModel>)
    );
  }

  /**
   * @return Success
   */
  GetUsersResponse(): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetUsers(): __Observable<Array<User>> {
    return this.GetUsersResponse().pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param user undefined
   * @return Success
   */
  InsUsersResponse(user?: User): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = user;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/InsUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @param user undefined
   * @return Success
   */
  InsUsers(user?: User): __Observable<Array<User>> {
    return this.InsUsersResponse(user).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param Id undefined
   * @return Success
   */
  GetStatementResponse(Id?: string): __Observable<__StrictHttpResponse<ConsolidatedAccount>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Id != null) __params = __params.set('Id', Id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetStatement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConsolidatedAccount>;
      })
    );
  }
  /**
   * @param Id undefined
   * @return Success
   */
  GetStatement(Id?: string): __Observable<ConsolidatedAccount> {
    return this.GetStatementResponse(Id).pipe(
      __map(_r => _r.body as ConsolidatedAccount)
    );
  }

  /**
   * @param Id undefined
   * @return Success
   */
  GetStatementCoffeeResponse(Id?: string): __Observable<__StrictHttpResponse<ConsolidatedAccountCoffee>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Id != null) __params = __params.set('Id', Id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetStatementCoffee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConsolidatedAccountCoffee>;
      })
    );
  }
  /**
   * @param Id undefined
   * @return Success
   */
  GetStatementCoffee(Id?: string): __Observable<ConsolidatedAccountCoffee> {
    return this.GetStatementCoffeeResponse(Id).pipe(
      __map(_r => _r.body as ConsolidatedAccountCoffee)
    );
  }

  /**
   * @param CPF undefined
   * @return Success
   */
  GetParticipantResponse(CPF?: string): __Observable<__StrictHttpResponse<Participant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (CPF != null) __params = __params.set('CPF', CPF.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetParticipant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Participant>;
      })
    );
  }
  /**
   * @param CPF undefined
   * @return Success
   */
  GetParticipant(CPF?: string): __Observable<Participant> {
    return this.GetParticipantResponse(CPF).pipe(
      __map(_r => _r.body as Participant)
    );
  }

  /**
   * @param user undefined
   * @return Success
   */
  UpUsersResponse(user?: User): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = user;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/UpUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @param user undefined
   * @return Success
   */
  UpUsers(user?: User): __Observable<Array<User>> {
    return this.UpUsersResponse(user).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param user undefined
   * @return Success
   */
  ChangePassUsersResponse(user?: User): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = user;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/ChangePassUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @param user undefined
   * @return Success
   */
  ChangePassUsers(user?: User): __Observable<Array<User>> {
    return this.ChangePassUsersResponse(user).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

   /**
   * @param obj undefined
   */
   UpdateClusterResponse(obj?: ClusterConfiguration): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Backoffice/UpdateCluster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param obj undefined
   */
  UpdatePdvPromotionResponse(obj?: PdvPromotion): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Backoffice/UpdatePdvPromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  UpdateCluster(obj?: ClusterConfiguration): __Observable<null> {
    return this.UpdateClusterResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param obj undefined
   */
  UpdatePdvPromotion(obj?: PdvPromotion): __Observable<null> {
    return this.UpdatePdvPromotionResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param obj undefined
   */
  InsertPdvPromotionResponse(obj?: PdvPromotion): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/InsertPdvPromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  InsertClusterResponse(obj?: ClusterConfiguration): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/InsertCluster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }


  /**
   * @param obj undefined
   */
  InsertCluster(obj?: ClusterConfiguration): __Observable<null> {
    return this.InsertClusterResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }
  /**
   * @param obj undefined
   */
  InsertPdvPromotion(obj?: PdvPromotion): __Observable<null> {
    return this.InsertPdvPromotionResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }
  /**
   * @param obj undefined
   */
  UpdatePointFactorPromotionResponse(obj?: PointFactorPromotion): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Backoffice/UpdatePointFactorPromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  UpdatePointFactorPromotion(obj?: PointFactorPromotion): __Observable<null> {
    return this.UpdatePointFactorPromotionResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param obj undefined
   */
  InsertPointFactorPromotionResponse(obj?: PointFactorPromotion): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/InsertPointFactorPromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  InsertPointFactorPromotion(obj?: PointFactorPromotion): __Observable<null> {
    return this.InsertPointFactorPromotionResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param obj undefined
   */
  ValidatorPointFactorPromotionResponse(obj?: PointFactorPromotion): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/ValidatorPointFactorPromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  ValidatorPointFactorPromotion(obj?: PointFactorPromotion): __Observable<null> {
    return this.ValidatorPointFactorPromotionResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  GetPdvPromotionResponse(): __Observable<__StrictHttpResponse<Array<PdvPromotion>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetPdvPromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PointFactorPromotion>>;
      })
    );
  }
   /**
   * @return Success
   */
   GetEcommercePromotionResponse(): __Observable<__StrictHttpResponse<Array<EcommercePromotion>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetEcommercePromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EcommercePromotion>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPdvPromotion(): __Observable<Array<PdvPromotion>> {
    return this.GetPdvPromotionResponse().pipe(
      __map(_r => _r.body as Array<PdvPromotion>)
    );
  }

   /**
   * @return Success
   */
   GetEcommercePromotion(): __Observable<Array<EcommercePromotion>> {
    return this.GetEcommercePromotionResponse().pipe(
      __map(_r => _r.body as Array<EcommercePromotion>)
    );
  }

  /**
   * @return Success
   */
  GetPointFactorPromotionResponse(): __Observable<__StrictHttpResponse<Array<PointFactorPromotion>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetPointFactorPromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PointFactorPromotion>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetClusterConfiguration(): __Observable<Array<ClusterConfiguration>> {
  return this.GetClusterConfigurationResponse().pipe(
    __map(_r => _r.body as Array<ClusterConfiguration>)
  );
  }
  /**
   * @return Success
   */
  GetClusterConfigurationResponse(): __Observable<__StrictHttpResponse<Array<ClusterConfiguration>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetCluster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClusterConfiguration>>;
      })
    );
  }

  /**
   * @return Success
   */
  GetPointFactorPromotion(): __Observable<Array<PointFactorPromotion>> {
    return this.GetPointFactorPromotionResponse().pipe(
      __map(_r => _r.body as Array<PointFactorPromotion>)
    );
  }

  /**
   * @return Success
   */
  GetRescuePromotionResponse(): __Observable<__StrictHttpResponse<Array<RescuePromotion>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetRescuePromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RescuePromotion>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetRescuePromotion(): __Observable<Array<RescuePromotion>> {
    return this.GetRescuePromotionResponse().pipe(
      __map(_r => _r.body as Array<RescuePromotion>)
    );
  }

  /**
   * @param obj undefined
   */
  UpdateRescuePromotionResponse(obj?: RescuePromotion): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Backoffice/UpdateRescuePromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  UpdateEcommercePromotionResponse(obj?: EcommercePromotion): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Backoffice/UpdateEcommercePromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  UpdateRescuePromotion(obj?: RescuePromotion): __Observable<null> {
    return this.UpdateRescuePromotionResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }
   /**
   * @param obj undefined
   */
   UpdateEcommercePromotion(obj?: EcommercePromotion): __Observable<null> {
    return this.UpdateEcommercePromotionResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param obj undefined
   */
  InsertRescuePromotionResponse(obj?: RescuePromotion): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/InsertRescuePromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
   /**
   * @param obj undefined
   */
   InsertEcommercePromotionResponse(obj?: EcommercePromotion): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/InsertEcommercePromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  InsertRescuePromotion(obj?: RescuePromotion): __Observable<null> {
    return this.InsertRescuePromotionResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }
   /**
   * @param obj undefined
   */
   InsertEcommercePromotion(obj?: EcommercePromotion): __Observable<null> {
    return this.InsertEcommercePromotionResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param obj undefined
   */
  ValidatorRescuePromotionResponse(obj?: RescuePromotion): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/ValidatorRescuePromotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  ValidatorRescuePromotion(obj?: RescuePromotion): __Observable<null> {
    return this.ValidatorRescuePromotionResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
 * @param obj undefined
 */
  ReportParticipantResponse(obj?: ReportParticipant[]): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/ReportsParticipants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  ReportParticipant(obj?: ReportParticipant[]): __Observable<ReportParticipant[]> {
    return this.ReportParticipantResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
  * @param obj undefined
  */
  GetParticipantByIDResponse(id?: string): __Observable<__StrictHttpResponse<ReportParticipant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = id;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetParticipantByID/` + id,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportParticipant>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  GetParticipantByID(id?: string): __Observable<ReportParticipant> {
    return this.GetParticipantByIDResponse(id).pipe(
      __map(_r => _r.body as ReportParticipant)
    );
  }

  /**
* @param obj undefined
*/
  FilterParticipantResponse(filter?: FilterParticipant): __Observable<__StrictHttpResponse<ReportParticipant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = filter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + '/api/Backoffice/FilterParticipant/',
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportParticipant>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  FilterParticipant(id?: FilterParticipant): __Observable<ReportParticipant> {
    return this.FilterParticipantResponse(id).pipe(
      __map(_r => _r.body as ReportParticipant)
    );
  }

  /**
* @param obj undefined
*/
  GetReportByCPFResponse(cpf?: string): __Observable<__StrictHttpResponse<ReportParticipant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + '/api/Backoffice/GetByCPF/' + cpf,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportParticipant>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  GetReportByCPF(cpf?: string): __Observable<ReportParticipant> {
    return this.GetReportByCPFResponse(cpf).pipe(
      __map(_r => _r.body as ReportParticipant)
    );
  }
  /**
* @param obj undefined
*/
  ReportCupomResponse(filter?: FilterCupom): __Observable<__StrictHttpResponse<ReportParticipant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = filter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + '/api/Backoffice/ReportsCupom/',
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportParticipant>;
      })
    );
  }
  ReportLojaResponse(filter?: FilterLoja): __Observable<__StrictHttpResponse<ReportParticipant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = filter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + '/api/Backoffice/ReportsLoja/',
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportParticipant>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  ReportCupom(filter?: FilterCupom): __Observable<ReportParticipant> {
    return this.ReportCupomResponse(filter).pipe(
      __map(_r => _r.body as ReportParticipant)
    );
  }
  ReportLoja(filter?: FilterLoja): __Observable<ReportParticipant> {
    return this.ReportLojaResponse(filter).pipe(
      __map(_r => _r.body as ReportParticipant)
    );
  }
  /**
  * @return Success
  */
  GetVoucherResponse(): __Observable<__StrictHttpResponse<Array<Voucher>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/GetVoucher`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Voucher>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetVoucher(): __Observable<Array<Voucher>> {
    return this.GetVoucherResponse().pipe(
      __map(_r => _r.body as Array<Voucher>)
    );
  }
  /**
* @param obj undefined
*/
  FilterVoucherResponse(filter?: FilterVoucher): __Observable<__StrictHttpResponse<Voucher>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = filter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + '/api/Backoffice/FilterVoucher/',
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Voucher>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  FilterVoucher(id?: FilterVoucher): __Observable<Voucher> {
    return this.FilterVoucherResponse(id).pipe(
      __map(_r => _r.body as Voucher)
    );
  }
  /**
 * @param obj undefined
 */
  GetVoucherByCPFResponse(cpf?: string): __Observable<__StrictHttpResponse<Voucher>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + '/api/Backoffice/GetVoucherByCPF/' + cpf,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Voucher>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  GetVoucherByCPF(cpf?: string): __Observable<Voucher> {
    return this.GetVoucherByCPFResponse(cpf).pipe(
      __map(_r => _r.body as Voucher)
    );
  }

   /**
   * @param obj undefined
   */
   ImportVoucherResponse(file?: FormData): __Observable<any> {
    let __headers = new HttpHeaders();
    __headers.append('Content-Type', 'multipart/form-data');
    __headers.append('Accept', 'application/json');

    const httpOptions = { headers: __headers };

    return this.http.post(this.rootUrl + '/api/Backoffice/ImportVoucher/', file, httpOptions);
  }
  /**
   * @param obj undefined
   */
  ImportEcommercePromotionCpfResponse(obj?: EcommercePromotionCpfFile): __Observable<any> {
    let __headers = new HttpHeaders();
    __headers.append('Content-Type', 'multipart/form-data');
    __headers.append('Accept', 'application/json');
    const formData: FormData = new FormData();
    formData.append('file', obj.file);
    formData.append('idEcommercePromotion', obj.idEcommercePromotion);

    const httpOptions = { headers: __headers };
    return this.http.post(this.rootUrl + '/api/Backoffice/ImportEcommercePromotionCpf/', formData, httpOptions);
  }
   /**
   * @param obj undefined
   */
   ImportPvdPromotionCpfResponse(obj?: PdvPromotionCpfFile): __Observable<any> {
    let __headers = new HttpHeaders();
    __headers.append('Content-Type', 'multipart/form-data');
    __headers.append('Accept', 'application/json');
    const formData: FormData = new FormData();
    formData.append('file', obj.file);
    formData.append('idPdvPromotion', obj.idPdvPromotion);

    const httpOptions = { headers: __headers };
    return this.http.post(this.rootUrl + '/api/Backoffice/ImportPdvPromotionCpf/', formData, httpOptions);
  }
  /**
   * @param obj undefined
   */
  ImportEcommercePromotionCpf(file): __Observable<any> {
    return this.ImportEcommercePromotionCpfResponse(file).pipe(
      __map(_r => _r as any)
    );
  }
   /**
   * @param obj undefined
   */
   ImportPdvPromotionCpf(file): __Observable<any> {
    return this.ImportPvdPromotionCpfResponse(file).pipe(
      __map(_r => _r as any)
    );
  }
  /**
   * @param obj undefined
   */
  ImportVoucher(file): __Observable<any> {
    return this.ImportVoucherResponse(file).pipe(
      __map(_r => _r as any)
    );
  }
  ExportVoucherResponse(): __Observable<__StrictHttpResponse<Voucher>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + '/api/Backoffice/ExportVoucher/',
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Voucher>;
      })
    );
  }

  ExportVoucherDataResponse(listVouchers: Array<Voucher>): __Observable<__StrictHttpResponse<Voucher>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + '/api/Backoffice/ExportVoucherData/',
      __body = listVouchers,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Voucher>;
      })
    );
  }

  ExportVoucherCampanhaResponse(): __Observable<__StrictHttpResponse<Voucher>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + '/api/Backoffice/ExportVoucherCampanha/',
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Voucher>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  ExportVoucherCampanha(): __Observable<Voucher> {
    return this.ExportVoucherCampanhaResponse().pipe(
      __map(_r => _r.body as Voucher)
    );
  }
  /**
   * @param obj undefined
   */
  ExportVoucher(): __Observable<Voucher> {
    return this.ExportVoucherResponse().pipe(
      __map(_r => _r.body as Voucher)
    );
  }
  /**
   * @param obj undefined
   */
  ExportVoucherData(listVouchers: Array<Voucher>): __Observable<Voucher> {
    return this.ExportVoucherDataResponse(listVouchers).pipe(
      __map(_r => _r.body as Voucher)
    );
  }
  /**
   * @param prt undefined
   */
  UpdateParticipantResponse(prt?: Participant): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = prt;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/UpdateParticipant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param prt undefined
   */
  UpdateParticipant(prt?: Participant): __Observable<null> {
    return this.UpdateParticipantResponse(prt).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
  * @param prt undefined
  */
  UpdateParticipantBlacklistResponse(prt?: Participant): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = prt;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/UpdateParticipantBlacklist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param prt undefined
   */
  UpdateParticipantBlacklist(prt?: Participant): __Observable<null> {
    return this.UpdateParticipantBlacklistResponse(prt).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param prt undefined
   */
  UpdateParticipantEmailResponse(prt?: Participant): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = prt;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/UpdateParticipantEmail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param prt undefined
   */
  UpdateParticipantEmail(prt?: Participant): __Observable<null> {
    return this.UpdateParticipantEmailResponse(prt).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param stateId undefined
   * @return Success
   */
  CheckVoucherCode(voucherCode?: Array<any>): __Observable<Array<any>> {
    return this.CheckVoucherCodeResponse(voucherCode).pipe(
      __map(_r => _r.body as Array<any>)
    );
  }

  CheckVoucherCodeResponse(voucherCode?: Array<any>): __Observable<__StrictHttpResponse<Array<any>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = voucherCode;
    if (voucherCode != null) __params = __params.set('voucherCode', voucherCode.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/CheckVoucherCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
 * @param obj undefined
 */
  RelatorioConsolidatedParticipant(dateInicial?: string, dateFinal?: string, storesIds?: string, cpf?: string): __Observable<Object[]> {
    return this.RelatorioConsolidatedParticipantResponse(dateInicial, dateFinal, storesIds, cpf).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
* @param obj undefined
*/
  RelatorioConsolidatedParticipantResponse(dateInicial?: string, dateFinal?: string, storesIds?: string, cpf?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    if (dateInicial != null) __params = __params.set('inicial', dateInicial);
    if (dateFinal != null) __params = __params.set('final', dateFinal);
    if (storesIds != null) __params = __params.set('storesIds', storesIds);
    if (cpf != null) __params = __params.set('cpf', cpf);

    console.log(dateInicial);
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Backoffice/RelatorioConsolidatedParticipant`,
      __body,
      {
        headers: __headers,
        params: __params,
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  InsertBlacklist(data: any) {
    let __headers = new HttpHeaders();
    let __body: any = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/InsertBlacklist`,
      __body,
      {
        headers: __headers,
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  DeleteBlacklist(data: any) {
    let __headers = new HttpHeaders();
    let __body: any = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/DeleteBlacklist`,
      __body,
      {
        headers: __headers,
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

}

module BackofficeService {
}

export { BackofficeService }
