import { FormControl, ValidatorFn, AbstractControl } from "@angular/forms";
import { RescuePromotion } from "./../../api/models/rescue-promotion";
import { PermissionGroup } from "./../../api/models/permission-group";
import {
  PointFactorPromotion,
  StoreModel,
  ProductModel,
  PermissionGroupRequest
} from "src/app/api/models";
import { PdvPromotion } from "src/app/api/models/pdv-promotion";
import { PointFactorCoffeeRegister } from "src/app/api/models/point-factor-coffee-register";
import { ClusterConfiguration } from "src/app/api/models/cluster-configuration";
import { EcommercePromotion } from "src/app/api/models/ecommerce-promotion";

const userID = localStorage.getItem("currentUser");
export const PermsNames = {
  Insere_permissoes: "INS_PERMISSION",
  Insere_promoçoes: "INS_PROMOTION",
  Insere_Store: "INS_STORE",
  Insere_User: "INS_USER",
  Lista_Fator: "LST_FACTOR",
  Lista_Permissoes: "LST_PERMISSION",
  Lista_promoçoes: "LST_PROMOTION",
  lista_Store: "LST_STORE",
  Lista_User: "LST_USER",
  Atualiza_fator: "UP_FACTOR",
  Atualiza_permissoes: "UP_PERMISSION",
  Update_promoçoes: "UP_PROMOTION",
  atualiza_Store: "UP_STORE",
  Atualiza_User: "UP_USER"
};

export type PermsTypes =
  | "INS_PERMISSION"
  | "INS_PROMOTION"
  | "INS_STORE"
  | "INS_USER"
  | "INS_PRODUCTS"
  | "INS_FACTOR"
  | "LST_FACTOR"
  | "LST_PERMISSION"
  | "LST_PROMOTION"
  | "LST_STORE"
  | "LST_USER"
  | "LST_PRODUCTS "
  | "LST_REPORT"
  | "UP_FACTOR"
  | "UP_PERMISSION"
  | "UP_PROMOTION"
  | "UP_STORE"
  | "UP_USER"
  | "UP_PRODUCTS"
  | "DEL_SAC";

export class AppConst {
  static logged = false;
  static loading = false;
  static alert = "";
  static valueform = "";
  static StatusButtonAlert = false;
  static ReturnButtonAlert = false;
  static userPermissions = [];
  static pointFactorPromotion: PointFactorPromotion;
  static pointFactorCoffee: PointFactorCoffeeRegister;
  static pdvPromotion: PdvPromotion;
  static active = false;
  static rescuePromotion: RescuePromotion = new Object();
  static store: ProductModel = new Object();
  static product: StoreModel = new Object();
  static permissionGroup: PermissionGroupRequest = new Object();
  static pseudoItem: any;
  static cluster: ClusterConfiguration;
  static ecommercePromotion: EcommercePromotion;



  static formatCNPJ = cnpj => {
    cnpj = cnpj.replace(/\D/g, "");

    let ret = "";

    let companyDigits =
      cnpj.substr(0, 8).match(/(\d{1,2})(\d{0,3})(\d{0,3})/) || [];
    const branchDigits = cnpj.substr(8, 4);
    const checkDigits = cnpj.substr(12, 2);

    // remove a primeia resposta do match() que corresponde ao resultado completo do regex
    if (companyDigits.length == 4) {
      companyDigits.splice(0, 1);
    }

    companyDigits = companyDigits.filter(d => d !== "").join(".");

    ret += companyDigits;
    if (branchDigits) {
      ret += "/" + branchDigits;
    }
    if (branchDigits && checkDigits) {
      ret += "-" + checkDigits;
    }

    return ret;
  };

  static formatPhone = phone => {
    phone = phone.toString().replace(/\D/g, "");
    let ret = "";

    const ddd = phone.substr(0, 2);
    const digits = phone.substr(2, 9).match(/(\d{1,5})(\d{0,4})/) || [];

    if (digits.length === 3) {
      digits.splice(0, 1);
    }

    if (ddd) {
      ret += `(${ddd}${digits.length ? ") " : ""}`;
    }
    if (digits) {
      ret += digits.filter(d => d !== "").join("-");
    }

    return ret;
  };

  static formatCEP = cep => {
    cep = cep.replace(/\D/g, "");

    let ret = "";

    const threeSpots = cep.match(/(\d{1,5})/g) || [];
    if (threeSpots.length === 3) {
      threeSpots.splice(2, 1);
    }

    if (threeSpots.length > 0) {
      ret = threeSpots.join("-");
    }

    return ret;
  };

  static logout = () => {};
}

export const checkForPermission = perm => {
  if (perm) {
    return (
      !!localStorage.getItem("currentUser") &&
      Array.from(
        new Set(
          AppConst.userPermissions.map(item => item.permission.name.trim())
        )
      ).find(permission => permission == perm.trim())
    );
  }
};

export const CustomValidators = {
  ageCheck: value => {
    const date = new Date(value);
    return (
      date.toString() != "Invalid Date" &&
      date.getTime() < new Date().getTime() - 1000 * 60 * 60 * 24 * 364 * 16
    );
  }
};
