/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ParticipantRequest } from '../models/participant-request';
import { Participant } from '../models/participant';
import { ParticipantLogin } from '../models/participant-login';
@Injectable({
  providedIn: 'root',
})
class WebSiteService extends __BaseService {
  static readonly GetByIdPath = '/api/Site/GetById';
  static readonly InsertParticipantPath = '/api/Site/InsertParticipante';
  static readonly UpdateParticipantePath = '/api/Site/UpdateParticipante';
  static readonly UpdateParticipantePasswordPath = '/api/Site/UpdateParticipantePassword';
  static readonly AccountCancelingPath = '/api/Site/AccountCanceling';
  static readonly GetStatementPath = '/api/Site/GetStatement';
  static readonly GetStatementNewPath = '/api/Site/GetStatementNew';
  static readonly GetParticipantPath = '/api/Site/GetParticipant';
  static readonly LoginPath = '/api/Site/Login';
  static readonly ForgetPasswordPath = '/api/Site/ForgetPassword';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param obj undefined
   */
  GetByIdResponse(obj?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (obj != null) __params = __params.set('obj', obj.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Site/GetById`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  GetById(obj?: string): __Observable<null> {
    return this.GetByIdResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param prt undefined
   */
  InsertParticipantResponse(prt?: ParticipantRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = prt;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Site/InsertParticipante`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param prt undefined
   */
  InsertParticipant(prt?: ParticipantRequest): __Observable<null> {
    return this.InsertParticipantResponse(prt).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param prt undefined
   */
  UpdateParticipanteResponse(prt?: Participant): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = prt;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Site/UpdateParticipante`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param prt undefined
   */
  UpdateParticipante(prt?: Participant): __Observable<null> {
    return this.UpdateParticipanteResponse(prt).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param prt undefined
   */
   UpdateParticipantePasswordResponse(prt?: Participant): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = prt;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Site/UpdateParticipantePassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
    /**
   * @param prt undefined
   */
     UpdateParticipantePassword(prt?: Participant): __Observable<null> {
      return this.UpdateParticipantePasswordResponse(prt).pipe(
        __map(_r => _r.body as null)
      );
    }

  /**
   * @param prt undefined
   */
  AccountCancelingResponse(prt?: Participant): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = prt;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Site/AccountCanceling`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param prt undefined
   */
  AccountCanceling(prt?: Participant): __Observable<null> {
    return this.AccountCancelingResponse(prt).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param Id undefined
   */
  GetStatementResponse(Id?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Id != null) __params = __params.set('Id', Id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Site/GetStatement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param Id undefined
   */
  GetStatement(Id?: string): __Observable<null> {
    return this.GetStatementResponse(Id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param Id undefined
   */
  GetStatementNewResponse(Id?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Id != null) __params = __params.set('Id', Id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Site/GetStatementNew`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param Id undefined
   */
  GetStatementNew(Id?: string): __Observable<null> {
    return this.GetStatementNewResponse(Id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param Id undefined
   */
  GetParticipantResponse(Id?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Id != null) __params = __params.set('Id', Id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Site/GetParticipant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param Id undefined
   */
  GetParticipant(Id?: string): __Observable<null> {
    return this.GetParticipantResponse(Id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param login undefined
   */
  LoginResponse(login?: ParticipantLogin): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = login;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Site/Login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param login undefined
   */
  Login(login?: ParticipantLogin): __Observable<null> {
    return this.LoginResponse(login).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param cpf undefined
   */
  ForgetPasswordResponse(cpf?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (cpf != null) __params = __params.set('cpf', cpf.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Site/ForgetPassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param cpf undefined
   */
  ForgetPassword(cpf?: string): __Observable<null> {
    return this.ForgetPasswordResponse(cpf).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module WebSiteService {
}

export { WebSiteService }
