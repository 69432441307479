; import { Router } from '@angular/router';
import { BackofficeService } from 'src/app/api/services';
import { AppConst, checkForPermission } from './../shared/model/AppConst';
import { Component, OnInit, AfterViewInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import * as XLSX from 'xlsx';
import { ConfirmationDialogService } from '../shared/alert/confirmation-dialog.service'

declare var jQuery: any;

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss'],
  providers: [ConfirmationDialogService]
})
export class BlacklistComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  blacklistType = '';
  loading = false;
  fileName = '';
  listCpfs = [];

  constructor(private fb: FormBuilder,
    private services: BackofficeService,
    private confirmationDialogService: ConfirmationDialogService) {

  }

  ngAfterViewInit(): void {

  }

  ngOnInit() {


  }

  buildForm() {

  }

  setBlacklistType(type: string, input) {
    this.blacklistType = type;
    input.click();
  }

  openConfirmationDialog(CPFs: any) {

    let messageInsert = `Deseja inserir na blacklist os ${CPFs.length} CPFs... ?`;

    let messageDelete = `Deseja remover da blacklist os ${CPFs.length} CPFs... ?`;

    let message = this.blacklistType == 'I' ? messageInsert : messageDelete

    this.confirmationDialogService.confirm('Confirmação...', message)
      .then((confirmed) => {
        if (confirmed == true) {
          const obj: ParticipantsByCPFsRequest = {
            cpf: CPFs,
          };

          if (this.blacklistType == 'I') {
            this.services.InsertBlacklist(obj).subscribe(x => {
              AppConst.StatusButtonAlert = false;
              AppConst.alert = 'CPFs inseridos com sucesso!';
              AppConst.loading = false;
            },
              err => {
                (AppConst.alert = 'Erro: ' + err.error);
                window.location.reload();
              }
            );
          } else {
            if (this.blacklistType == 'D') {
              this.services.DeleteBlacklist(obj).subscribe(x => {
                AppConst.StatusButtonAlert = false;
                AppConst.alert = 'CPFs removidos com sucesso!';
                AppConst.loading = false;
              },
                err => {
                  (AppConst.alert = 'Erro: ' + err.error);
                  window.location.reload();
                }
              );
            }
          }
        }
      }
      )
      .catch(() => console.log('O usuário dispensou a caixa de diálogo (por exemplo, usando ESC, clicando no ícone de cruz ou clicando fora da caixa de diálogo)'));
  }

  onFileChange(evt: any) {
    try {

      this.listCpfs = [];

      this.loading = true;

      const target: DataTransfer = <DataTransfer>evt.target;

      if (target.files.length !== 1) {
        throw new Error('Não é possível usar vários arquivos');
      }

      const reader: FileReader = new FileReader();

      reader.readAsBinaryString(target.files[0]);

      const cancel = () => {
        this.loading = false;
        this.fileName = '';
      };

      reader.onabort = cancel;
      reader.onerror = cancel;

      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
          type: 'binary',
          cellDates: true,
        });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        const data = <any>(
          XLSX.utils.sheet_to_json(ws, { header: 1, raw: false })
        );

        data.map((x: any) => x.map(s => this.listCpfs.push(s)));

        this.openConfirmationDialog(this.listCpfs);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }
}

interface ParticipantsByCPFsRequest {
  cpf: string[];
}

