import { StoreModel } from './../../api/models/store-model';
import { Store } from './../../api/models/store';
import { Router } from '@angular/router';
import { BackofficeService, WebSiteService } from 'src/app/api/services';
import { Voucher, Participant } from 'src/app/api/models';
import { AppConst, checkForPermission } from './../../shared/model/AppConst';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { FilterVoucher } from 'src/app/api/models/filter-voucher';
declare var jQuery: any;

@Component({
  selector: 'app-voucher-list',
  templateUrl: './voucher-list.component.html',
  styleUrls: ['./voucher-list.component.scss']
})
export class VoucherListComponent implements OnInit {
  item: Voucher;
  pass: FormGroup;
  form: FormGroup;
  cpfNotFound = true;
  participant: Participant;
  options: Select2Options;
  VoucherL:Voucher[] = [];
  filtroV:FilterVoucher;
  voucher: Voucher;
  items: Voucher[] = [];
  VoucherList: Voucher[] = [];
  campanha: string[] = [];
  searchValue = '';
  dtStart: string;
  dtEnd: string;
  primeiravez=0;
  constructor(private fb:FormBuilder,
    private services: BackofficeService,
    private website: WebSiteService,
    private http: HttpClient,
    private router: Router) 
    {
    this.item = new Object();
    this.filtroV = new Object();
  }

  ngOnInit() {
    this.buildForm();
    this.getService();
    this.campanha.push("Nha benta de aniversario");
      this.campanha.push("Voucher Itaú Personnalité: troca de Língua de Gato");
      this.campanha.push("Teste Cash Back Pascoa");
      this.campanha.push("Voucher Promoção Páscoa: 50% de desconto na próxima compra");
  }
  getService() {
    AppConst.loading = true;
    this.services.GetVoucher().subscribe(res => {
      const obj = res as any;
      this.items = obj.value != null ? obj.value.data : null;
      AppConst.loading = false;
    });
  }

   select() {
    this.services.GetVoucher().subscribe(s => {
      var r = s["value"]["data"];
      this.voucher = s["value"]["data"];
      this.form.get("documentNumber").setValue("");
      this.form.get("id").setValue("");
      this.form.get("name").setValue("");
      this.VoucherList = [];
      this.VoucherList.push(this.voucher);
  
   
    });
  }
  
  filtrar(){
      AppConst.loading = true;
      this.primeiravez = 1;
      this.services.FilterVoucher(this.filtroV).subscribe(s => {
        this.VoucherList = s["value"]["data"];
        setTimeout(() => {
          AppConst.loading = false;
        }, 1000);
     
      }, error =>{
        AppConst.loading = false;
      });
    
  }

  getCPF() {
    if (this.form.get("documentNumber").valid) {
      AppConst.loading = true;
      const cpf = this.form.get("documentNumber").value.replace(/\D/g, "");

      if (cpf.length < 11) {
        AppConst.alert = "CPF incompleto ou inválido";
        AppConst.loading = false;
        return;
      }

      this.services.GetVoucherByCPF(cpf).subscribe(s => {
        var r = s["value"]["data"];
        this.voucher = s["value"]["data"];
        AppConst.loading = false;
        this.form.get("documentNumber").setValue("");
        this.form.get("name").setValue("");
        this.voucher = s["value"]["data"];
        this.VoucherList = [];
        this.VoucherList.push(this.voucher);
     
      });
    } else {
      this.cpfNotFound = true;
    }
  }

  getCPFNavigate() {
    if (this.participant.id && !this.form.get("documentNumber").valid) {
      this.router.navigate(["/sac/list", this.participant.id]);
    }

    if (this.form.get("documentNumber").valid) {
      AppConst.loading = true;
      const cpf = this.form.get("documentNumber").value.replace(/\D/g, "");

      if (cpf.length < 11) {
        AppConst.alert = "CPF incompleto ou inválido";
        AppConst.loading = false;
        return;
      }

      this.services.GetVoucherByCPF(cpf).subscribe(
        res => {
          const obj = res as any;
          const vou =
            obj.value && obj.value.data
              ? (obj.value.data as Voucher)
              : null;

          if (!vou) {
            AppConst.alert = "CPF não existente na base";
            AppConst.loading = false;

            return;
          }
          this.voucher = vou;
          this.router.navigate(["/sac/list", this.participant.cpf]);

          this.form.get("name").setValue(vou.name);
          this.form.get("id").setValue(vou.id);
          this.form.get("dtSrat").setValue(vou.dtStart);
          this.form.get("dtEnd").setValue(vou.dtEnd);
          this.form.get("cie").setValue(vou.cie);
          this.form.get("cmpcode").setValue(vou.cpmCode);
          this.form.get("nameP").setValue(vou.nameP);
          this.form.get("reserved").setValue(vou.reserved);
          this.form.get("reservedString").setValue(vou.reservedString);
          this.form.get("participantId").setValue(vou.participantId);
          this.form.get("cpf").setValue(vou.cpf);
          this.form.get("storeid").setValue(vou.storeId);
          this.form.get("storename").setValue(vou.storeName);
          this.form.get("value").setValue(vou.value);
          AppConst.loading = false;
          this.cpfNotFound = false;
        },
        err => {
          AppConst.alert = "CPF Não Encontrado!";
          this.cpfNotFound = true;
          AppConst.loading = false;
        }
      );
    } else {
      this.cpfNotFound = true;
    }
  }

  formatCPF() {
    const cpf = this.form.get("documentNumber").value.replace(/\D/g, "");
    let ret = "";

    const threeSpots = cpf.match(/(\d{1,3})/g) || [];
    const finalDigits = threeSpots.length === 4 ? threeSpots[3] : "";

    (() => threeSpots.length === 4 && threeSpots.splice(3, 1))();

    if (threeSpots.length > 0) {
      ret = threeSpots.join(".");
    }
    if (threeSpots.length > 0 && finalDigits) {
      ret += "-" + finalDigits;
    }
    this.form.get("documentNumber").setValue(ret);
  }
 
  buildForm() {
    this.voucher = new Object();
    this.form = this.fb.group({
      documentNumber: new FormControl(
        this.filtroV.CPF,
        Validators.required
      ),
      dtStart: new FormControl(this.filtroV.dtStart,Validators.required),
      dtEnd: new FormControl(this.filtroV.dtEnd),
      RescuePromotion: new FormControl(this.filtroV.RescuePromotion,Validators.required),
      id: new FormControl(this.voucher.id, Validators.required),
      name: new FormControl(this.voucher.name, Validators.required),
      nameP: new FormControl(this.voucher.nameP, Validators.required),
      discont: new FormControl(this.voucher.discont, Validators.required),
      value: new FormControl(this.voucher.value),
      cie: new FormControl(this.voucher.cie),
      campanha: new FormControl(this.filtroV.Campanha),
      reservedString: new FormControl(this.voucher.reservedString),
      participantId: new FormControl(this.voucher.participantId),
      codigo: new FormControl(this.voucher.codigo),
    });
  }
}