import { AppConst } from './../shared/model/AppConst';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../api/services';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss', '../../assets/css/main.css', '../../assets/css/util.css']
})
export class PasswordRecoveryComponent implements OnInit {
  getemail: string;

  activepass = true;
  constructor(  public services: UserService,
    public router: Router) { }

  ngOnInit() {

  }
  redirectlogin() {
    if (this.activepass === false) {
      this.activepass = true;
    } else {
      this.activepass = false;
    }
  }
  passrecovery() {
      setTimeout(() => {
        AppConst.alert = '';
        if (this.activepass === false) {
          this.activepass = true;
        } else {
          this.activepass = false;
        }
      }, 4000);
  }
}
