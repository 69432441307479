import { LoginComponent } from './login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { FooterComponent } from './shared/components/footer/footer.component';

import { FormsComponent } from './forms/forms.component';

import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { PointFactorComponent } from './point-factor/point-factor.component';
import { HeaderInterceptor } from './interceptor.service';
import { SharedModule } from './shared/shared.module';

import { registerLocaleData, DatePipe } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { ReportsComponent } from './reports/reports.component';
import { RelatorioComponent } from './relatorios/relatorio.component';
import { VoucherListComponent } from './voucher/voucher-list/voucher-list.componet';
import { BlacklistComponent } from './blacklist/blacklist.componet';
import { Select2Module } from 'ng2-select2';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ConfirmationDialogService } from './shared/alert/confirmation-dialog.service'
import { ConfirmationDialogComponent } from './shared/alert/confirmation-dialog.component'
import { PointFactorCoffeeComponent } from './point-factor-coffee/point-factor-coffee.component';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    FormsComponent,
    LoginComponent,
    PasswordRecoveryComponent,
    PointFactorComponent,
    ReportsComponent,
    VoucherListComponent,
    BlacklistComponent,
    RelatorioComponent,
    ConfirmationDialogComponent,
    PointFactorCoffeeComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    AppRoutingModule,
    SharedModule,
    Select2Module,
    NgxCaptchaModule
  ],
  providers: [
    ConfirmationDialogService,
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HeaderInterceptor,
        multi: true,
        DatePipe
      }
    ]
  ],
  bootstrap: [AppComponent],
  exports: [ConfirmationDialogComponent],
  entryComponents: [ConfirmationDialogComponent]
})
export class AppModule { }
