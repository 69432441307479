import { BackofficeService } from 'src/app/api/services';
import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { AppConst } from './shared/model/AppConst';
import { Router, NavigationStart } from '@angular/router';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked {
  isAuthenticated = false;
  isCounting = false;

  constructor(private cdRef: ChangeDetectorRef, private router: Router, public backoffice: BackofficeService) {
    this.timeLeft();
    AppConst.logout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('token_time_end');
      this.isAuthenticated = false;
      this.isCounting = false;
      AppConst.loading = false;
      AppConst.alert = 'Seu token expirou, por favor realize o login novamente';
    };
    this.getPermissions();
    router.events.subscribe(route => {
      if (route instanceof NavigationStart) {
        this.checkPermission(route);
      }
    });
  }

  ngAfterViewChecked() {
    const token = localStorage.getItem('token');

    this.isAuthenticated = !!token;

    // check if it change, tell CD update view
    this.cdRef.detectChanges();
  }

  getPermissions() {
    const userID = localStorage.getItem('currentUser') || '';
    if (!userID) {
      return;
    }

    this.backoffice.UserPermission(+userID).subscribe(permitions => {
      AppConst.userPermissions = permitions['value']['data'];
    });
  }

  timeLeft() {
    const timerEnd = localStorage.getItem('token_time_end');
    if (timerEnd) {
      const ms = new Date().getTime();
      const msEnd = new Date(timerEnd).getTime();

      const diff = msEnd - ms;

      if (diff <= 0) {
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token_time_end');
        this.isAuthenticated = false;
        this.isCounting = false;
        AppConst.alert = 'Seu token expirou, por favor realize o login novamente';
        return;
      }

      this.isCounting = true;

      const timeLeft = interval(1000);
      const takeTimeLeft = timeLeft.pipe(take(diff / 1000));

      takeTimeLeft.subscribe(
        time => {},
        err => err,
        () => {
          localStorage.removeItem('token');
          localStorage.removeItem('currentUser');
          localStorage.removeItem('token_time_end');
          this.isAuthenticated = false;
          this.isCounting = false;
          AppConst.alert = 'Seu token expirou, por favor realize o login novamente';
        }
      );
    }
  }

  get Loading() {
    return AppConst.loading;
  }

  get Alert() {
    return AppConst.alert;
  }
  get StatusButtonAlert() {
    return AppConst.StatusButtonAlert;
  }
  get Logged() {
    return AppConst.logged;
  }

  closeAlert() {
    AppConst.alert = '';
  }
  CallbackAlert() {
    AppConst.ReturnButtonAlert = true;
  }

  checkPermission(route) {
    const token = localStorage.getItem('token');

    if (this.isAuthenticated && !token) {
      AppConst.alert = 'Seu token expirou, por favor realize o login novamente';
    }

    if (!this.isCounting) {
      this.timeLeft();
    }

    this.isAuthenticated = !!token;
  }
}
