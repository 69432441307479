import { BackofficeService } from "src/app/api/services";
import {
  FormBuilder,
  FormGroup,
  FormControl
} from "@angular/forms";
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { AppConst } from "src/app/shared/model/AppConst";
import { FilterParticipant } from '../api/models/filter-participant';
import { StoreModel } from '../api/models';
import { isNullOrUndefined } from 'util';
import { ExcelService } from 'src/app/api/services/excel.service';
declare var jQuery: any;

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorio.component.html',
  styleUrls: ['./relatorio.component.scss']
})
export class RelatorioComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  filtroPart: FilterParticipant;

  dataInicial: string;
  dataFinal: string;
  cpf: string;

  itemsStore: Array<Select2OptionData> = [];
  storeKeyWord = '';
  stores: StoreModel[] = [];
  selectedStore = [];

  constructor(
    private fb: FormBuilder,
    private services: BackofficeService,
  ) {
    this.filtroPart = new Object();
  }

  exportar() {
    try {
      var out = this.verificaData();
      if (out) {
        var dateInicial = this.filtroPart.DtInicial != null ? this.filtroPart.DtInicial.toString() : null;
        var dateFinal = this.filtroPart.DtFinal != null ? this.filtroPart.DtFinal.toString() : null;
        var cpf = this.filtroPart.CPF;
        var storesIds = this.selectedStore.map((s) => s.id).toString();
        console.log(dateInicial);
        console.log(dateFinal);
        console.log(cpf);
        console.log(storesIds);
        AppConst.loading = true;
        this.services.RelatorioConsolidatedParticipant(dateInicial, dateFinal, storesIds, '01009258982').subscribe(s => {
          ExcelService.exportAsExcelFile(s, 'Extrato');
          AppConst.loading = false;
        }, error => {
          AppConst.loading = false;
        });
      }
    } catch (e) {
      alert(e);
    }
  }

  ngOnInit() {
    this.buildForm();
    this.selectDropdowns();
  }

  ngAfterViewInit() {
    jQuery(document).on("keyup", ".select2-search__field", e => {
      const item = $(".select2-search__field").val();
      if (item.length > 3) {
      }
    });
    jQuery("#symbolId").on("select2:select", e => {
    });
  }

  verificaData() {
    if (this.filtroPart.CPF == null) {
      if (this.filtroPart.DtFinal == null) {
        alert('A data Final tem que ser preenchida.');
        AppConst.loading = false;
        return false;
      }
      if (this.filtroPart.DtInicial == null) {
        alert('A data Inicial tem que ser preenchida.');
        AppConst.loading = false;
        return false;
      } if (this.dataFinal != null && this.dataFinal.substring(0, 1) != '0') {
        var dateInicial = new Date(this.dataInicial);
        var dateFinal = new Date(this.dataFinal);

        var diferencaTempo = dateFinal.getTime() - dateInicial.getTime();
        var difirencaDias = diferencaTempo / (1000 * 3600 * 24);

        if (!(dateFinal.getTime() >= dateInicial.getTime() && difirencaDias <= 30)) {
          alert('A data Final tem que ser igual ou maior que a data Inicial. E tem que ter no máximo um intervalo de 30 dias.');
          return false;
        }
      }
      return true;
    } else {
      return true;
    }
  }

  buildForm() {
    this.form = this.fb.group({
      dataInicial: new FormControl(this.filtroPart.DtInicial),
      dataFinal: new FormControl(this.filtroPart.DtFinal),
      cpf: new FormControl(this.filtroPart.CPF)
    });
  }


  async selectDropdowns() {
    const stores = await this.services.GetStores().toPromise();
    this.stores = stores['value'] != null ? (stores['value'].data as StoreModel[]) : null;

    this.itemsStore = this.stores.map(store => ({
      id: store['id'],
      text: store.name,
      selected: false
    }));
  }

  get filteredStoreList() {
    return this.itemsStore.filter(item =>
      item.text.toLowerCase().match(this.storeKeyWord)
    );
  }

  changeDropdown(item) {
    if (this.selectedStore.find(store => store.id === item.id)) {
      this.selectedStore.splice(
        this.selectedStore.findIndex(store => store.id === item.id),
        1
      );
    } else {
      this.selectedStore.push(item);
    }
  }
}

export interface Select2OptionData {
  id: string;
  text: string;
  disabled?: boolean;
  children?: Array<Select2OptionData>;
  additional?: any;
  selected: boolean;
}