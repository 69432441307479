import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductModel, StoreModel } from '../api/models';
import { PointFactorCoffeeRegister } from '../api/models/point-factor-coffee-register';
import { PointFactorCoffeeUpdate } from '../api/models/point-factor-coffee-update';
import { BackofficeService } from '../api/services';
import { AppConst } from 'src/app/shared/model/AppConst';
import { PromotionCoffeeStore } from '../api/models/point-factor-coffee-store';
import { getLocaleDateTimeFormat } from '@angular/common';
import { PromotionCoffeeProductsPoints } from '../api/models/point-factor-coffee-products-points';
import { PromotionCoffeeProductsRescue } from '../api/models/point-factor-coffee-products-rescue';

@Component({
    selector: 'app-point-factor-coffee',
    templateUrl: './point-factor-coffee.component.html',
    styleUrls: ['./point-factor-coffee.component.scss']
})
export class PointFactorCoffeeComponent implements OnInit {
    pointFactorCoffeeForm: FormGroup;
    item: PointFactorCoffeeRegister;
    itemUpdate: PointFactorCoffeeUpdate;
    promotionCoffeeStore: PromotionCoffeeStore[] = [];
    promotionCoffeeProductsPoints: PromotionCoffeeProductsPoints[] = [];
    promotionCoffeeProductsRescue: PromotionCoffeeProductsRescue[] = [];
    constructor(
        private services: BackofficeService,
        private fb: FormBuilder,
    ) {
        this.item = new Object();
    }

    ngOnInit() {
        this.options = {
            multiple: true,
            theme: 'classic',
            closeOnSelect: false,
            width: '100%'
        }
        this.getServicePointFactorCoffee();
        this.selectDropdowns();
    }

    itemsStore: Array<Select2OptionData> = [];
    itensProductPoints: Array<Select2OptionData> = [];
    itensProductRescue: Array<Select2OptionData> = [];
    selectedStore = [];
    selectedProductPoints = [];
    selectedProductRescue = [];
    stores: StoreModel[] = [];
    productsPoints: ProductModel[] = [];
    productsRescue: ProductModel[] = [];
    options: Select2Options;

    storeKeyWord = '';
    productPointsKeyWord = '';
    productRescueKeyWord = '';

    changeDropdown(item, which) {
        switch (which) {
            case 'store':
                if (this.selectedStore.find(store => store.id === item.id)) {
                    this.selectedStore.splice(
                        this.selectedStore.findIndex(store => store.id === item.id),
                        1
                    );
                } else {
                    this.selectedStore.push(item);
                }
                break;

            case 'productPoint':
                if (this.selectedProductPoints.find(product => product.id === item.id)) {
                    this.selectedProductPoints.splice(
                        this.selectedProductPoints.findIndex(product => product.id === item.id),
                        1
                    );
                } else {
                    this.selectedProductPoints.push(item);
                }
                break;

            case 'productRescue':
                if (this.selectedProductRescue.find(product => product.id === item.id)) {
                    this.selectedProductRescue.splice(
                        this.selectedProductRescue.findIndex(product => product.id === item.id),
                        1
                    );
                } else {
                    this.selectedProductRescue.push(item);
                }
                break;

            default:
                break;
        }
    }

    getServicePointFactorCoffee() {
        AppConst.loading = true;
        this.buildForm();
        this.services.GetCoffe().subscribe(
            res => {
                const obj = res as any;
                const coffee =
                    obj.value && obj.value.data
                        ? (obj.value.data as PointFactorCoffeeRegister)
                        : null;
                this.itemUpdate = coffee;
                this.pointFactorCoffeeForm.get("nome").setValue(coffee.nome);
                this.pointFactorCoffeeForm.get("dataHoraInsert").setValue(coffee.dataHoraInsert.substring(0, 10));
                this.pointFactorCoffeeForm.get("active").setValue(coffee.active);
                this.pointFactorCoffeeForm.get("daysToExpire").setValue(coffee.daysToExpire);
                this.item = this.itemUpdate;
                const array = res as any;
                if (array.value.data.promotionCoffeeStore.length > 0) {
                    this.promotionCoffeeStore = array.value.data.promotionCoffeeStore;
                }
                if (array.value.data.promotionCoffeeProductsPoints.length > 0) {
                    this.promotionCoffeeProductsPoints = array.value.data.promotionCoffeeProductsPoints;
                }
                if (array.value.data.promotionCoffeeProductsRescues.length > 0) {
                    this.promotionCoffeeProductsRescue = array.value.data.promotionCoffeeProductsRescues;
                }

                AppConst.loading = false;
            },
            err => {
                AppConst.alert = "Campanha não encontrada!";
                AppConst.loading = false;
            }
        );
    }

    buildForm() {
        this.itemUpdate = new Object();
        this.pointFactorCoffeeForm = this.fb.group({
            nome: new FormControl(this.itemUpdate.nome, Validators.required),
            dataHoraInsert: new FormControl(this.itemUpdate.dataHoraInsert, Validators.required),
            active: new FormControl(this.itemUpdate.active, Validators.required),
            daysToExpire: new FormControl(this.itemUpdate.daysToExpire, Validators.required)
        });
    }

    isFormFieldValid(field) {
        if (!!this.pointFactorCoffeeForm.get(field)) {
            return this.pointFactorCoffeeForm.get(field).valid;
        }
    }

    isFormFieldTouched(field) {
        if (!!this.pointFactorCoffeeForm.get(field)) {
            return this.pointFactorCoffeeForm.get(field).touched;
        }
    }

    get filteredStoreList() {
        return this.itemsStore.filter(item =>
            item.text.toLowerCase().match(this.storeKeyWord)
        );
    }

    get filteredProducPointstList() {
        return this.itensProductPoints.filter(item =>
            item.text.toLowerCase().match(this.productPointsKeyWord)
        );
    }

    get filteredProducRescuetList() {
        return this.itensProductRescue.filter(item =>
            item.text.toLowerCase().match(this.productRescueKeyWord)
        );
    }

    async selectDropdowns() {
        const stores = await this.services.GetStores().toPromise();
        const products = await this.services.GetProducts().toPromise();
        this.stores =
            stores['value'] != null ? (stores['value'].data as StoreModel[]) : null;

        this.productsPoints =
            products['value'] != null ? (products['value'].data as ProductModel[]) : null;

        this.productsRescue =
            products['value'] != null ? (products['value'].data as ProductModel[]) : null;

        this.itemsStore = this.stores.map(store => ({
            id: store['id'],
            text: store.name,
            selected: false
        }));

        this.itensProductPoints = this.productsPoints.map(product => ({
            id: product['id'],
            text: product.cie + ' - ' + product.name,
            selected: false
        }));

        this.itensProductRescue = this.productsRescue.map(product => ({
            id: product['id'],
            text: product.cie + ' - ' + product.name,
            selected: false
        }));

        AppConst.loading = false;
    }

    insertStore() {
        if (this.selectedStore.length > 0) {
            this.selectedStore.forEach(store => {
                let aux: PromotionCoffeeStore = {
                    promotionCoffeeId: this.item.id,
                    storeId: store.id,
                    active: true,
                    nomeLoja: store.text
                }
                this.promotionCoffeeStore.push(aux);
            });
        }
        this.selectedStore = [];
        AppConst.alert = 'Loja Inserida com sucesso, clique em Salvar para completar a alteração!';
        setTimeout(
            function () {
                AppConst.alert = '';
            }, 5000);
    }
    insertProductPoints() {
        if (this.selectedProductPoints.length > 0) {
            this.selectedProductPoints.forEach(product => {
                let aux: PromotionCoffeeProductsPoints = {
                    promotionCoffeeId: this.item.id,
                    productId: product.id,
                    active: true,
                    points: 0,
                    nameProduct: product.text
                }
                this.promotionCoffeeProductsPoints.push(aux);
            });
        }
        this.selectedProductPoints = [];
        AppConst.alert = 'Produto Inserido com Sucesso, clique em Salvar para completar a alteração!';
        setTimeout(
            function () {
                AppConst.alert = '';
            }, 5000);
    }
    insertProductRescue() {
        if (this.selectedProductRescue.length > 0) {
            this.selectedProductRescue.forEach(product => {
                let aux: PromotionCoffeeProductsRescue = {
                    promotionCoffeeId: this.item.id,
                    productId: product.id,
                    active: true,
                    points: 0,
                    nameProduct: product.text
                }
                this.promotionCoffeeProductsRescue.push(aux);
            });
        }
        this.selectedProductRescue = [];
        AppConst.alert = 'Produto Inserido com Sucesso, clique em Salvar para completar a alteração!';
        setTimeout(
            function () {
                AppConst.alert = '';
            }, 5000);
    }

    removeStore(store) {
        let index = this.promotionCoffeeStore.indexOf(store);
        if (index > -1) {
            if (store.id > 0) {
                store.active = false;
            }
            else {
                this.promotionCoffeeStore.splice(index, 1);
            }
        }
    }
    removeProductPoints(product) {
        let index = this.promotionCoffeeProductsPoints.indexOf(product);
        if (index > -1) {
            this.promotionCoffeeProductsPoints.splice(index, 1);
        }
    }
    removeProductRescue(product) {
        let index = this.promotionCoffeeProductsRescue.indexOf(product);
        if (index > -1) {
            this.promotionCoffeeProductsRescue.splice(index, 1);
        }
    }

    saveForm() {
        AppConst.loading = true;
        this.itemUpdate = { ...this.itemUpdate, ...this.pointFactorCoffeeForm.value };
        this.itemUpdate.store = this.promotionCoffeeStore;
        this.itemUpdate.productsPoints = this.promotionCoffeeProductsPoints;
        this.itemUpdate.productsRescue = this.promotionCoffeeProductsRescue;
        if (this.itemUpdate.dataHoraInsert == null) {
            this.itemUpdate.dataHoraInsert = this.item.dataHoraInsert;
        }
        if (this.itemUpdate.active == null) {
            this.itemUpdate.active = false;
        }
        this.services.UpdateCoffe(this.item.id, this.itemUpdate).subscribe((res: any) => {
            if (res.value.error) {
                AppConst.alert = res.value.message;
                return;
            }
            AppConst.alert = 'Fator de Pontos Café Salvo com Sucesso!';
            AppConst.loading = false;
        }, err => {
            AppConst.alert = "Ocorreu um erro inesperado";
            AppConst.loading = false;
            return;
        }
        );
    }
}

export interface Select2OptionData {
    id: string;
    text: string;
    disabled?: boolean;
    children?: Array<Select2OptionData>;
    additional?: any;
    selected: boolean;
}
