/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserLogin } from '../models/user-login';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly ValidateUserPath = '/api/User';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param request undefined
   * @return Success
   */
  ValidateUserResponse(request?: UserLogin): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();

    __headers.append('Access-Control-Allow-Origin', '*');

    let __body: any = null;
    __body = request;

    // __headers.append('Access-Control-Allow-Origin', '*');
    // __headers.append('Access-Control-Allow-Methods', 'PUT, POST, DELETE, GET, OPTIONS');
    // __headers.append('Access-Control-Allow-Headers', 'Accept, Authorization, Content-Type');
    // __headers.append('Access-Control-Allow-Credentials', 'true');
    
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  ValidateUser(request?: UserLogin): __Observable<string> {
    return this.ValidateUserResponse(request).pipe(
      __map(_r => _r.body as string)
    );
  }

  ValidateCodeTwoFactoryResponse(code: string): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Backoffice/ValidateCodeTwoFactory?code=` + code,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }
  
  ValidateCodeTwoFactory(code: string): __Observable<string> {
    return this.ValidateCodeTwoFactoryResponse(code).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module UserService {
}

export { UserService }
