import { AppConst, checkForPermission } from './shared/model/AppConst';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const permNames = {
      users: 'LST_USER',
      promoCampaign: 'LST_PROMOTION',
      promoRescue: 'LST_PROMOTION',
      promoPdv: 'LST_PROMOTION',
      permissionGroup: 'LST_PERMISSION',
      store: 'LST_STORE',
      products: 'LST_PRODUCTS ',
      pointFactor: 'LST_FACTOR',
      pointFactorCoffee: 'LST_FACTOR',
      sac: 'LST_SAC',
      voucher:'LST_PERMISSION',
      reports: 'LST_REPORT',
      relatorios: 'LST_RELATORIO',
      blacklist: 'LST_BLACKLIST',
      clusterConfiguration:'LST_CLUSTER',
      promoEcommerce: 'LST_ECOMMERCE',
      '': ''
    };

    let pagePath = state.url.split('/')[1];

    const perm = permNames[pagePath];

    if (!perm) {
      pagePath = '';
    }
    
    return !!checkForPermission(perm);
  }
}
