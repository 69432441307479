import { NeedsPermissionDirective } from './directives/needs-permission.directive';
import { SharedComponent } from './shared.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { InputTagComponent } from './components/input-tag/input-tag.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [InputTagComponent, SharedComponent, NeedsPermissionDirective],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule.forRoot()
  ],
  exports: [
    InputTagComponent,
    NeedsPermissionDirective
  ]
})
export class SharedModule { }
