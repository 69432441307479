import { BackofficeService } from 'src/app/api/services';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConst } from '../shared/model/AppConst';
import { UserService } from '../api/services';
import jwtDecode from 'jwt-decode';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss',
    '../../assets/css/main.css',
    '../../assets/css/util.css'
  ]
})
export class LoginComponent implements OnInit {
  siteKey: string;
  form: FormGroup;
  login = '';
  password = '';
  activepass = true;
  emailmodel = '';
  passwordmodel = '';

  constructor(
    public router: Router,
    public fb: FormBuilder,
    public services: UserService,
    public backoffice: BackofficeService
  ) {
    //email usado para a geração da key: grupocrm641@gmail.com
    this.siteKey = '6LcpoSIdAAAAABft7u_G7i0D49Jd7bg8ehbgCv5u';
  }

  ngOnInit() {
    this.buildForms();
  }
  buildForms() {
    this.form = this.fb.group({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      recaptcha: new FormControl(null, Validators.required)
    });
  }

  onLoggedin() {
    if (this.form.controls.username.invalid || this.form.controls.password.invalid) {
      AppConst.alert = 'Preencha os campos faltantes';
      return;
    } else if (this.form.controls.recaptcha.invalid) {
      AppConst.alert = 'Confirme o captcha';
      return;
    }
    
    const user = this.form.value;

    this.services.ValidateUser(user).subscribe(
      res => {
        if (!res.match('{')) {

          var response = prompt('Digite o código recebido por e-mail para garantir sua segurança.');

          const userID = jwtDecode(res).unique_name[0];

          localStorage.setItem('currentUser', userID);

          const token = res
            .replace('"token:', '')
            .replace('"', '')
            .trim();
          localStorage.setItem('token', token);

          localStorage.setItem(
            'token_time_end',
            new Date(new Date().getTime() + 1200000).toString()
          );

          AppConst.loading = true;
          
          this.services.ValidateCodeTwoFactory(response).subscribe(response => {
            this.backoffice.UserPermission(userID).subscribe(permitions => {

              AppConst.userPermissions = permitions['value']['data'];

              const token = res
                .replace('"token:', '')
                .replace('"', '')
                .trim();
              localStorage.setItem('token', token);
              localStorage.setItem(
                'token_time_end',
                new Date(new Date().getTime() + 1200000).toString()
              );

              AppConst.alert = 'Usuário Logado com Sucesso!';

              this.router.navigate(['homepage']);

              setTimeout(() => {
                AppConst.alert = '';
              }, 1000);
              AppConst.loading = false;
            });
          }, err => {
            localStorage.clear();
            AppConst.loading = false;
            AppConst.alert = err.error;
          }

          )
        } else {
          AppConst.loading = false;
          AppConst.alert = 'Usuário ou senha inválida!';
        }
      }
    );
  }

  PassswordRecovery() {
    if (this.activepass === false) {
      this.activepass = true;
    } else {
      this.activepass = false;
    }
  }
}
