/* tslint:disable */
import { Injectable } from "@angular/core";

/**
 * Global configuration for Api services
 */
@Injectable({
  providedIn: "root"
})
export class ApiConfiguration {
  /* Produção */
  // rootUrl: string = 'https://api-servicos.kopclub.com.br';

  /* Homologação */
  rootUrl: string = 'https://api-servicos-homolog-kc.grupocrm.io';

  // /* localhost */
  // rootUrl: string = 'https://localhost:44382';
}

export interface ApiConfigurationInterface {
  rootUrl?: string;
}
