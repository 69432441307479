import { AppConst, checkForPermission } from 'src/app/shared/model/AppConst';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  dropdown = false;
  public samplePagesCollapsed = true;
  constructor(public router: Router) { }

  ngOnInit() {
  }

  hasPermission(perm) {
    return checkForPermission(perm);
  }

  get userPerms() {
    return !!localStorage.getItem('currentUser');
  }

  activedropdown() {
     if (this.dropdown === false) {
       this.dropdown = true;
     } else {
       this.dropdown = false;
     }
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('token_time_end');
    this.router.navigate(['/login']);
  }
}

