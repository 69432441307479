/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReverseCupom } from '../models/reverse-cupom';
import { ParticipantPDV } from '../models/participant-pdv';
import { Cupom } from '../models/cupom';
import { RescueRequest } from '../models/rescue-request';
@Injectable({
  providedIn: 'root',
})
class PDVService extends __BaseService {
  static readonly ReversePath = '/api/PDV/ReverseCupom';
  static readonly InsertParticipantPath = '/api/PDV/InsertParticipante';
  static readonly UpdateParticipantePath = '/api/PDV/UpdateParticipante';
  static readonly GetParticipantePath = '/api/PDV/GetParticipante';
  static readonly InsertCupomListNoCiclePath = '/api/PDV/InsertCupom';
  static readonly CalculPath = '/api/PDV/Calculate';
  static readonly ExpireRescuePath = '/api/PDV/ExpireRescue';
  static readonly InsertRescuePath = '/api/PDV/InsertRescue';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param Cupom undefined
   */
  ReverseResponse(Cupom?: ReverseCupom): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = Cupom;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PDV/ReverseCupom`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param Cupom undefined
   */
  Reverse(Cupom?: ReverseCupom): __Observable<null> {
    return this.ReverseResponse(Cupom).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param prt undefined
   */
  InsertParticipantResponse(prt?: ParticipantPDV): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = prt;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PDV/InsertParticipante`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param prt undefined
   */
  InsertParticipant(prt?: ParticipantPDV): __Observable<null> {
    return this.InsertParticipantResponse(prt).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param prt undefined
   */
  UpdateParticipanteResponse(prt?: ParticipantPDV): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = prt;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PDV/UpdateParticipante`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param prt undefined
   */
  UpdateParticipante(prt?: ParticipantPDV): __Observable<null> {
    return this.UpdateParticipanteResponse(prt).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PDVService.GetParticipanteParams` containing the following parameters:
   *
   * - `CPF`:
   *
   * - `CNPJ`:
   */
  GetParticipanteResponse(params: PDVService.GetParticipanteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.CPF != null) __params = __params.set('CPF', params.CPF.toString());
    if (params.CNPJ != null) __params = __params.set('CNPJ', params.CNPJ.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PDV/GetParticipante`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PDVService.GetParticipanteParams` containing the following parameters:
   *
   * - `CPF`:
   *
   * - `CNPJ`:
   */
  GetParticipante(params: PDVService.GetParticipanteParams): __Observable<null> {
    return this.GetParticipanteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param Cupom undefined
   */
  InsertCupomListNoCicleResponse(Cupom?: Array<Cupom>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = Cupom;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PDV/InsertCupom`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param Cupom undefined
   */
  InsertCupomListNoCicle(Cupom?: Array<Cupom>): __Observable<null> {
    return this.InsertCupomListNoCicleResponse(Cupom).pipe(
      __map(_r => _r.body as null)
    );
  }
  CalculResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PDV/Calculate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  Calcul(): __Observable<null> {
    return this.CalculResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  ExpireRescueResponse(id?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PDV/ExpireRescue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ExpireRescue(id?: string): __Observable<null> {
    return this.ExpireRescueResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param obj undefined
   */
  InsertRescueResponse(obj?: RescueRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = obj;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PDV/InsertRescue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param obj undefined
   */
  InsertRescue(obj?: RescueRequest): __Observable<null> {
    return this.InsertRescueResponse(obj).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PDVService {

  /**
   * Parameters for GetParticipante
   */
  export interface GetParticipanteParams {
    CPF?: string;
    CNPJ?: string;
  }
}

export { PDVService }
