import { AppConst } from 'src/app/shared/model/AppConst';
import { BackofficeService } from 'src/app/api/services';
import { PointFactorRegister } from './../api/models/point-factor-register';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { CityModel, StoreModel, StateModel } from '../api/models';
import { PointFactorRegionItem } from '../api/models/point-factor-region-item-model';
import { StepRegionModel } from '../api/models/step-region-model';


@Component({
    selector: 'app-point-factor',
    templateUrl: './point-factor.component.html',
    styleUrls: ['./point-factor.component.scss']
})
export class PointFactorComponent implements OnInit {
    pointFactorForm: FormGroup;
    item: PointFactorRegister;
    constructor(
        private services: BackofficeService,
        private fb: FormBuilder,
    ) {
        this.item = new Object();
    }

    ngOnInit() {
        this.options = {
            multiple: true,
            theme: 'classic',
            closeOnSelect: false,
            width: '100%'
        }
        this.getServicePointFactor();
        this.selectDropdowns();
    }


    itemsStore: Array<Select2OptionData> = [];
    itemsState: Array<Select2OptionData> = [];
    itemsCity: Array<Select2OptionData> = [];
    statesFilter: Array<Select2OptionData> = [];
    citiesFilter: Array<Select2OptionData> = [];
    selectedStore = [];
    selectedStates = [];
    selectedCities = [];
    selectedStatesFilter = [];
    selectedCitiesFilter = [];
    states: StateModel[] = [];
    cities: CityModel[] = [];
    statesSelected: StateModel[] = [];
    citiesSelected: CityModel[] = [];
    //listRegionItems: Array<PointFactorRegionItem> = [];
    stores: StoreModel[] = [];
    options: Select2Options;
    stepRegionFactor: number;
    listStepRegion: StepRegionModel[] = [];
    isValidRegion: Boolean = true;



    storeKeyWord = '';

    changeDropdown(item, which) {
        switch (which) {
            case 'statesFilter':
                this.selectedStatesFilter = item.data;
                this.selectCitiesFilter(item.data);

                break;

            case 'state':
                this.selectedStates = item.data;

                this.selectCities(item.data);

                break;

            case 'city':
                this.selectedCities = item.data;

                this.filterStores();

                break;

            case 'citiesFilter':
                this.selectedCitiesFilter = item.data;

                break;

            case 'store':
                if (this.selectedStore.find(store => store.id === item.id)) {
                    this.selectedStore.splice(
                        this.selectedStore.findIndex(store => store.id === item.id),
                        1
                    );
                } else {
                    this.selectedStore.push(item);
                }
                break;

            default:
                break;
        }
    }

    insertRegion() {
        if(this.stepRegionFactor == null){
            this.isValidRegion = false;
            return;
        }
        else{
            this.isValidRegion = true;
        }
        if (this.selectedStore.length > 0) {
            this.selectedStore.forEach(store => {
                let aux: StepRegionModel = {
                    idElement: store.id,
                    regionFactor: this.stepRegionFactor != null ? this.stepRegionFactor : 0,
                    type: 'Loja',
                    text: store.text
                }
                this.listStepRegion.push(aux);
            });
        }
        else if (this.selectedCities.length > 0) {
            this.selectedCities.forEach(city => {
                let aux: StepRegionModel = {
                    idElement: city.id,
                    regionFactor: this.stepRegionFactor != null ? this.stepRegionFactor : 0,
                    type: 'Cidade',
                    text: city.text
                }
                this.listStepRegion.push(aux);
            });
        }
        else if (this.selectedStates.length > 0) {
            this.selectedStates.forEach(state => {
                let aux: StepRegionModel = {
                    idElement: state.id,
                    regionFactor: this.stepRegionFactor != null ? this.stepRegionFactor : 0,
                    type: 'Estado',
                    text: state.text
                }
                this.listStepRegion.push(aux);
            });
        }

        this.selectedStates = [];
        this.selectedCities = [];
        this.selectedStore = [];
        this.stepRegionFactor = null;
    }

    removeRegion(region) {
        let index = this.listStepRegion.indexOf(region);
        if (index > -1) {
            this.listStepRegion.splice(index, 1);
        }
    }


    getServicePointFactor() {
        AppConst.loading = true;
        this.buildForm();
        this.services.GetResponse().subscribe(x => {
            const array = x || '';
            this.item = array['body'].value.data;

            if(array['body'].value.data.regions.length > 0){
                this.listStepRegion = array['body'].value.data.regions;
            }

            Object.entries(this.item).forEach(obj => {
                if (this.pointFactorForm.get(obj[0])) {
                    this.pointFactorForm.get(obj[0]).setValue(obj[1]);
                }
            });
            AppConst.loading = false;
        }, err => AppConst.loading = false);
    }

    buildForm() {
        this.pointFactorForm = this.fb.group({
            pdv: new FormControl('', Validators.required),
            siteFirstInsert: new FormControl('', Validators.required),
            siteComplete: new FormControl('', Validators.required),
            factor: new FormControl('', Validators.required),
            daysToExpire: new FormControl('', Validators.required),
            pointToRescue: new FormControl('', Validators.required),
            factorRegion: new FormControl(''),
            factorStores: new FormControl('')
        });
    }

    isFormFieldValid(field) {
        if (!!this.pointFactorForm.get(field)) {
            return this.pointFactorForm.get(field).valid;
        }
    }

    isFormFieldTouched(field) {
        if (!!this.pointFactorForm.get(field)) {
            return this.pointFactorForm.get(field).touched;
        }
    }
    resetFields() {
        this.buildForm();
    }

    saveForm() {
        AppConst.loading = true;
        this.item = { ...this.item, ...this.pointFactorForm.value };
        this.item.regions = this.listStepRegion;

        this.services.UpdateResponse(this.item).subscribe(res => {
            AppConst.alert = 'Fator de Pontos Salvo com Sucesso!';
            AppConst.loading = false;
            setTimeout(
                function () {
                    AppConst.alert = '';
                }, 1500);
        }, err => AppConst.loading = false);
    }

    async selectCities(items) {
        if (items.length > 0) {
            this.cities = [];
        }

        if (!items.length) {
            this.itemsCity = [];
            this.cities = [];
            this.selectedCities = [];
            this.filterStores();
            return;
        }

        let count = 0;

        items.forEach(async state => {
            try {
                const cities = await this.services.GetCities(state.id).toPromise();

                count++;

                if (cities['value'] && cities['value'].data.length > 0) {
                    this.cities.push(
                        ...cities['value'].data
                            .filter(city => !this.cities.find(cit => cit.id === city.id))
                            .map(city => city as CityModel[])
                    );
                }

                if (count == items.length) {
                    this.itemsCity = this.cities.map(city => ({
                        id: city.id,
                        text: city.name,
                        selected: !!this.selectedCities
                            .filter(cit => cit.selected)
                            .find(cit => cit.id === city.id)
                    }));
                    this.filterStores();
                }
            } catch (error) {
                this.filterStores();
            }
        });
    }

    async selectCitiesFilter(items) {

        if (items.length > 0) {
            this.citiesSelected = [];
        }

        if (!items.length) {
            this.citiesFilter = [];
            this.citiesSelected = [];
            this.selectedCitiesFilter = [];
            return;
        }

        let count = 0;

        items.forEach(async state => {
            try {
                const cities = await this.services.GetCities(state.id).toPromise();

                count++;

                if (cities['value'] && cities['value'].data.length > 0) {
                    this.citiesSelected.push(
                        ...cities['value'].data
                            .filter(city => !this.citiesSelected.find(cit => cit.id === city.id))
                            .map(city => city as CityModel[])
                    );
                }

                if (count == items.length) {
                    this.citiesFilter = this.citiesSelected.map(city => ({
                        id: city.id,
                        text: city.name,
                        selected: !!this.selectedCitiesFilter
                            .filter(cit => cit.selected)
                            .find(cit => cit.id === city.id)
                    }));

                }
            } catch (error) {
            }
        });
    }


    filterStores() {
        const map = items => {
            return (
                items.map(item => ({
                    id: item.id,
                    text: item.name,
                    selected: !!this.selectedStore.find(store => store.id === item.id)
                }))
            );
        };

        const filteredList = this.stores.filter(store => {
            if (this.selectedStates.length == 0) {
                return true;
            }

            if (this.selectedStates.length > 0 && this.selectedCities.length == 0) {
                return this.selectedStates.find(state => state.id === store.idState);
            }

            if (this.selectedStates.length > 0 && this.selectedCities.length > 0) {
                return this.selectedStates.find(state => {
                    const citiesThatAreInThisState = this.selectedCities.filter(
                        city =>
                            this.cities.find(ct => ct.id === city.id).idState === state.id
                    );

                    const storeIsInState = store.idState == state.id;

                    if (citiesThatAreInThisState.length > 0) {
                        const storeIsInAnyCity = citiesThatAreInThisState.find(
                            city => store.idCity == city.id
                        );

                        return storeIsInState && storeIsInAnyCity;
                    } else {
                        return storeIsInState;
                    }
                });
            }
        });
        this.itemsStore = map(filteredList);
    }

    get filteredStoreList() {
        return this.itemsStore.filter(item =>
            item.text.toLowerCase().match(this.storeKeyWord)
        );
    }

    async selectDropdowns() {
        const stores = await this.services.GetStores().toPromise();
        const states = await this.services.GetState().toPromise();

        this.stores =
            stores['value'] != null ? (stores['value'].data as StoreModel[]) : null;
        this.states =
            states['value'] != null ? (states['value'].data as StateModel[]) : null;
        this.statesSelected =
            states['value'] != null ? (states['value'].data as StateModel[]) : null;

        this.itemsStore = this.stores.map(store => ({
            id: store['id'],
            text: store.name,
            selected: false
        }));
        this.itemsState = this.states.map(state => ({
            id: state['id'],
            text: state['initials'] + ' - ' + state.name,
            selected: false
        }));
        this.statesFilter = this.statesSelected.map(state => ({
            id: state['id'],
            text: state['initials'] + ' - ' + state.name,
            selected: false
        }));

        console.log(stores);

        //this.backofficeService();

        AppConst.loading = false;
    }


}

export interface Select2OptionData {
    id: string;
    text: string;
    disabled?: boolean;
    children?: Array<Select2OptionData>;
    additional?: any;
    selected: boolean;
}