import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Participant } from "../api/models/participant";
import { BackofficeService, WebSiteService } from "src/app/api/services";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { User, ProductModel } from "src/app/api/models";
import { AppConst, CustomValidators } from "src/app/shared/model/AppConst";
import { ReportParticipant } from '../api/models/report-participant';
import { saveAs } from 'file-saver'
import { FilterParticipant } from '../api/models/filter-participant';
import { StateModel } from '../api/models/state-model';
import { FilterCupom } from '../api/models/filter-cupom';
import { isNullOrUndefined } from 'util';
declare var jQuery: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  pass: FormGroup;
  cpfNotFound = true;
  changePassword = false;
  userId = "";
  name = "";
  options: Select2Options;
  filtroPart: FilterParticipant;
  dataCadastro: Date;
  participant: Participant;
  itensParticipant: Participant[] = [];
  reportPart: ReportParticipant;
  reportPartList: ReportParticipant[] = [];
  dropdownList = [];
  states = [];
  cities = [];
  est: StateModel[] = [];
  fcupom: FilterCupom;
  dataInicial: string;
  dataFinal: string;
  dataCupomFinal: string;
  dataCupomInicial: string;
  cupom: boolean = false;
  loja: boolean = false;
  participante: boolean = true;
  primeiravez = 0;
  invalidAge = false;
  orig: string[] = [];
  product = "";
  items: ProductModel[] = [];

  constructor(
    private fb: FormBuilder,
    private services: BackofficeService,
    private website: WebSiteService,
    private http: HttpClient,
    private router: Router
  ) {
    this.options = {
      placeholder: "Digite o nome do participante...",
      allowClear: true,
      width: "100%"
    };
    this.filtroPart = new Object();
    this.fcupom = new Object();
  }
  get filtroProduto(): ProductModel[] {
    return (this.items) && this.items.filter(item => item.name.toUpperCase().match(this.product.toUpperCase())) || [];
  }
  fill(item) {
    this.services.GetParticipantByName(item).subscribe(s => {
      const items = (s["value"] && s["value"]["data"]) || [];
      this.dropdownList = items;
    });
  }
  ageCheck() {
    // chamando essa func no onKeyUp do input de data, usando a invalidAge pra validar input e mostrar a msg
    this.invalidAge = !CustomValidators.ageCheck(
      this.form.get("birthDate").value
    );
  }
  getProducts() {
    AppConst.loading = true;
    this.services.GetProducts().subscribe(res => {
      const obj = res as any;
      this.items = obj.value != null ? obj.value.data : null;
      AppConst.loading = false;
    });
  }

  exportar() {
    if (localStorage.getItem('dataCupomInvalida') != '1') {
      AppConst.loading = true;
      if (this.participante) {
        this.services.ReportParticipant(this.reportPartList).subscribe(s => {
          saveAs(s, 'report.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          AppConst.loading = false;
        }, error => {
          AppConst.loading = false;
        });
      }
      else if (this.loja) {
        var dateInicial = new Date(this.filtroPart.DtInicial);
        var dateFinal = new Date(this.filtroPart.DtFinal);
        if (this.filtroPart.DtInicial != null && this.filtroPart.DtFinal == null) {
          alert('A data Final tem que ser preenchida.');
          AppConst.loading = false;
        } else if (this.filtroPart.DtFinal != null && this.filtroPart.DtInicial == null) {
          alert('A data Inicial tem que ser preenchida.');
          AppConst.loading = false;
        } else if (dateFinal < dateInicial) {
          alert('A data Final tem que ser igual ou maior que a data Inicial.');
          AppConst.loading = false;
        }
        else this.services.ReportLoja(this.filtroPart).subscribe(s => {
          saveAs(s, 'relatorioLoja.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          AppConst.loading = false;
        }, error => {
          AppConst.loading = false;
        });
      }
      else {
        if (this.filtroPart != null && !isNullOrUndefined(this.filtroPart.CPF)) {
          this.fcupom.CPF = this.filtroPart.CPF;
        }
        this.services.ReportCupom(this.fcupom).subscribe(s => {
          saveAs(s, 'report.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          AppConst.loading = false;
        }, error => {
          AppConst.loading = false;
        });
      }
    }
    else {
      alert('A data Final tem que ser igual ou maior que a data Inicial. E tem que ter no máximo um intervalo de 30 dias.')

    }
  }

  ngOnInit() {
    this.buildForm();
    this.getState();
    this.est.push({ initials: "AC", name: "Acre" });
    this.est.push({ initials: "AL", name: "Alagoas" });
    this.est.push({ initials: "AM", name: "Amazonas" });
    this.est.push({ initials: "AP", name: "Amapá" });
    this.est.push({ initials: "BA", name: "Bahia" });
    this.est.push({ initials: "CE", name: "Ceará" });
    this.est.push({ initials: "DF", name: "Distrito Federal" });
    this.est.push({ initials: "GO", name: "Goiás" });
    this.est.push({ initials: "MA", name: "Maranhão" });
    this.est.push({ initials: "MG", name: "Minas Gerais" });
    this.est.push({ initials: "MS", name: "Mato Grosso do Sul" });
    this.est.push({ initials: "MT", name: "Mato Grosso" });
    this.est.push({ initials: "PA", name: "Pará" });
    this.est.push({ initials: "PB", name: "Paraíba" });
    this.est.push({ initials: "PE", name: "Pernambuco" });
    this.est.push({ initials: "PI", name: "Piauí" });
    this.est.push({ initials: "PR", name: "Paraná" });
    this.est.push({ initials: "RO", name: "Rondônia" });
    this.est.push({ initials: "RR", name: "Roraima" });
    this.est.push({ initials: "RS", name: "Rio Grande do Sul" });
    this.est.push({ initials: "RN", name: "Rio Grande do Norte" });
    this.est.push({ initials: "SE", name: "Sergipe" });
    this.est.push({ initials: "SP", name: "São Paulo" });
    this.est.push({ initials: "ES", name: "Espírito Santo" });
    this.est.push({ initials: "RJ", name: "Rio de Janeiro" });
    this.est.push({ initials: "TO", name: "Tocantins" });
    this.orig.push("PDV");
    this.orig.push("Site");
    this.getProducts();
  }
  ngAfterViewInit() {
    jQuery(document).on("keyup", ".select2-search__field", e => {
      const item = $(".select2-search__field").val();
      if (item.length > 3) {
        this.fill(item);
      }
    });
    jQuery("#symbolId").on("select2:select", e => {
    });
  }

  validEmail(email) {
    const reg = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    return email.match(reg) !== null && email == email.match(reg)[0];
  }

  validBirthdate(birthdate) {
    if (!birthdate) {
      return false;
    }

    const date = birthdate.split("-")[0];
    const today = new Date().getFullYear();

    return Math.abs(date - today) >= 16;
  }

  ativarParticipante() {
    this.participante = true;
    this.cupom = false;
    this.loja = false;
  }
  ativarLoja() {
    this.loja = true;
    this.cupom = false;
    this.participante = false;
  }
  ativarCupom() {
    this.cupom = true;
    this.participante = false;
    this.loja = false;
  }
  verificaDataCupom() {

    if (this.dataCupomFinal != null && this.dataCupomFinal.substring(0, 1) != '0') {
      var dateInicial = new Date(this.dataCupomInicial);
      var dateFinal = new Date(this.dataCupomFinal);

      var diferencaTempo = dateFinal.getTime() - dateInicial.getTime();
      var difirencaDias = diferencaTempo / (1000 * 3600 * 24);

      if (!(dateFinal.getTime() >= dateInicial.getTime() && difirencaDias <= 30)) {

        alert('A data Final tem que ser igual ou maior que a data Inicial. E tem que ter no máximo um intervalo de 30 dias.')
        localStorage.setItem('dataCupomInvalida', '1');
      }
      else {
        localStorage.setItem('dataCupomInvalida', '0');
      }

    }

  }
  verificaData() {

    if (this.dataFinal != null && this.dataFinal.substring(0, 1) != '0') {
      var dateInicial = new Date(this.dataInicial);
      var dateFinal = new Date(this.dataFinal);

      var diferencaTempo = dateFinal.getTime() - dateInicial.getTime();
      var difirencaDias = diferencaTempo / (1000 * 3600 * 24);

      if (!(dateFinal.getTime() >= dateInicial.getTime() && difirencaDias <= 30)) {

        alert('A data Final tem que ser igual ou maior que a data Inicial. E tem que ter no máximo um intervalo de 30 dias.')
        localStorage.setItem('dataInvalida', '1');
      }
      else {
        localStorage.setItem('dataInvalida', '0');
      }

    }

  }

  filtrar() {
    if (localStorage.getItem('dataInvalida') != '1') {
      AppConst.loading = true;
      this.primeiravez = 1;

      this.services.FilterParticipant(this.filtroPart).subscribe(s => {
        this.reportPartList = s["value"]["data"];
        setTimeout(() => {
          AppConst.loading = false;
        }, 1000);

      }, error => {
        AppConst.loading = false;
      });
    }
    else {
      alert('A data Final tem que ser igual ou maior que a data Inicial. E tem que ter no máximo um intervalo de 30 dias.')

    }
  }

  selectProduct(product: ProductModel) {
    this.product = product.name;
  }

  select(user) {
    this.participant = user;
    this.services.GetParticipantByID(user.id).subscribe(s => {
      var r = s["value"]["data"];
      this.reportPart = s["value"]["data"];
      this.form.get("documentNumber").setValue("");
      this.form.get("name").setValue("");
      this.reportPartList = [];
      this.reportPartList.push(this.reportPart);

    });
  }

  close(div) {
    setTimeout(() => div.classList.remove("show"), 200);
  }
  procuraProduto() {
    if (this.name.length > 3) {
      this.fill(this.name);
    }
  }

  searchName() {
    if (this.name.length > 3) {
      this.fill(this.name);
    }
  }

  changeDropdown(event) {
  }
  fillName(item) {
  }

  getCPFNavigate() {
    if (this.participant.id && !this.form.get("documentNumber").valid) {
      this.router.navigate(["/sac/list", this.participant.id]);
    }

    if (this.form.get("documentNumber").valid) {
      AppConst.loading = true;
      const cpf = this.form.get("documentNumber").value.replace(/\D/g, "");

      if (cpf.length < 11) {
        AppConst.alert = "CPF incompleto ou inválido";
        AppConst.loading = false;
        return;
      }

      this.services.GetParticipant(cpf).subscribe(
        res => {
          const obj = res as any;
          const user =
            obj.value && obj.value.data
              ? (obj.value.data as Participant)
              : null;

          if (!user) {
            AppConst.alert = "CPF não existente na base";
            AppConst.loading = false;

            return;
          }
          this.participant = user;

          this.router.navigate(["/sac/list", this.participant.id]);

          this.form.get("name").setValue(user.name);
          this.form.get("nickname").setValue(user.nickname);
          this.form.get("email").setValue(user.email);
          this.form.get("sex").setValue(user.sex);
          this.form.get("zipCode").setValue(user.zipCode);
          this.form.get("birthDate").setValue(user.birthdate.substring(0, 10));
          this.form.get("address").setValue(user.address);
          this.form.get("telephone").setValue(user.telephone);
          this.form.get("blackList").setValue(user.blacklist);
          this.form.get("addressComplement").setValue(user.addressComplement);
          this.form.get("addressNumber").setValue(user.addressNumber);
          this.form.get("city").setValue(user.city);
          this.form.get("state").setValue(user.state);


          if (user.state) {
            this.getCity(user.state);
          }

          AppConst.loading = false;
          this.cpfNotFound = false;
        },
        err => {
          AppConst.alert = "CPF Não Encontrado!";
          this.cpfNotFound = true;
          AppConst.loading = false;
        }
      );
    } else {
      this.cpfNotFound = true;
    }
  }

  limpar() {
    this.cpfNotFound = true;
    this.changePassword = false;
    this.userId = "";
    this.participant = {};

    this.buildForm();
  }

  getState() {
    this.services
      .GetState()
      .subscribe(res => (this.states = res["value"]["data"]));
  }

  getCity(state) {
    this.services
      .GetCities(state)
      .subscribe(res => (this.cities = res["value"]["data"]));
  }

  changeUserPassword() {
    if (!this.cpfNotFound && this.pass.get("password").valid) {
      if (this.pass.get("password").value !== this.pass.get("confirm").value) {
        AppConst.alert = "Senhas não são iguais";
        return;
      }

      this.participant.password = this.pass.get("password").value;
      this.website
        .UpdateParticipantePassword(this.participant)
        .subscribe(res => {
          AppConst.StatusButtonAlert = false;
          AppConst.alert = "Senha Atualizado com Sucesso!";
        });
    }
  }

  async getAddressFromCEP() {
    return;
    const address = await this.http
      .get("http://localhost:4200/api/11420410/json/")
      .toPromise();
  }

  formatCPF() {
    const cpf = this.form.get("documentNumber").value.replace(/\D/g, "");
    let ret = "";

    const threeSpots = cpf.match(/(\d{1,3})/g) || [];
    const finalDigits = threeSpots.length === 4 ? threeSpots[3] : "";

    (() => threeSpots.length === 4 && threeSpots.splice(3, 1))();

    if (threeSpots.length > 0) {
      ret = threeSpots.join(".");
    }
    if (threeSpots.length > 0 && finalDigits) {
      ret += "-" + finalDigits;
    }
    this.form.get("documentNumber").setValue(ret);
  }

  formatCEP() {
    const val = this.form.get("zipCode").value.replace(/\D/g, "");
    const firstHalf = val.substr(0, 5);
    const secondHalf = val.substr(5, 8);

    let ret = "";
    ret = firstHalf;
    if (secondHalf) {
      ret += "-" + secondHalf;
    }

    this.form.get("zipCode").setValue(ret);
  }

  buildForm() {
    this.participant = new Object();
    this.form = this.fb.group({
      documentNumber: new FormControl(
        this.participant.cpf,
        Validators.required
      ),
      dataInicial: new FormControl(this.filtroPart.DtInicial),
      dataFinal: new FormControl(this.filtroPart.DtFinal),
      origem: new FormControl(this.filtroPart.Origem),
      blackList: new FormControl(this.participant.blacklist),
      name: new FormControl(this.participant.name, Validators.required),
      email: new FormControl(this.participant.email, Validators.required),
      nickname: new FormControl(this.participant.neighborhood),
      sex: new FormControl(this.participant.sex),
      birthDate: new FormControl(
        this.participant.birthdate,
        Validators.required
      ),
      zipCode: new FormControl(this.participant.zipCode),
      address: new FormControl(this.participant.address),
      addressComplement: new FormControl(this.participant.addressComplement),
      addressNumber: new FormControl(this.participant.addressNumber),
      telephone: new FormControl(
        this.participant.telephone,
        Validators.required
      ),
      city: new FormControl(this.participant.city),
      state: new FormControl(this.participant.state)
    });
    this.pass = this.fb.group({
      password: new FormControl("", Validators.required),
      confirm: new FormControl("", Validators.required)
    });
  }

  update() {
    if (this.invalidAge) {
      return;
    }

    if (!this.form.get("name").value) {
      AppConst.alert =
        "Nome inválido. Verifique o campo Nome e tente novamente.";
      return;
    }

    if (this.form.get("telephone").value.length !== 11) {
      AppConst.alert =
        "Número de telefone inválido. Verifique o campo Telefone e tente novamente.";
      return;
    }

    if (!this.validEmail(this.form.get("email").value)) {
      AppConst.alert =
        "E-mail em formato inválido. Verifique o campo Email e tente novamente.";
      return;
    }

    if (!this.validEmail(this.form.get("nome").value)) {
      AppConst.alert =
        "Nome inválido, favor inserir nome completo do participante.";
      return;
    }

    if (this.form.valid) {
      this.participant.businessPhone = 0;
      this.participant.celularNumber = 0;
      this.participant.agreedTermsConditions =
        this.participant.agreedTermsConditions != null
          ? this.participant.agreedTermsConditions
          : false;
      this.participant.veracity =
        this.participant.veracity != null ? this.participant.veracity : false;
      this.participant.receive_Email =
        this.participant.receive_Email != null
          ? this.participant.receive_Email
          : false;
      this.participant.receiveSms =
        this.participant.receiveSms != null
          ? this.participant.receiveSms
          : false;
      this.participant.partialRegistry =
        this.participant.partialRegistry != null
          ? this.participant.partialRegistry
          : false;
      this.participant.completedRegistry != null
        ? this.participant.completedRegistry
        : false;
      const participant = { ...this.participant, ...this.form.value };
      this.website.UpdateParticipante(participant).subscribe(
        res => {
          AppConst.StatusButtonAlert = false;
          AppConst.alert = "Participante Atualizado com Sucesso!";
        },
        err => {
          AppConst.alert = "Ocorreu um erro inesperado";
        }
      );
    }
  }

  cancel() { }

  getCPF() {
    if (this.form.get("documentNumber").valid) {
      AppConst.loading = true;
      const cpf = this.form.get("documentNumber").value.replace(/\D/g, "");

      if (cpf.length < 11) {
        AppConst.alert = "CPF incompleto ou inválido";
        AppConst.loading = false;
        return;
      }

      this.services.GetReportByCPF(cpf).subscribe(s => {
        var r = s["value"]["data"];
        this.reportPart = s["value"]["data"];
        AppConst.loading = false;
        this.form.get("documentNumber").setValue("");
        this.form.get("name").setValue("");
        this.reportPart = s["value"]["data"];
        this.reportPartList = [];
        this.reportPartList.push(this.reportPart);

      });
    } else {
      this.cpfNotFound = true;
    }
  }
}

export interface Select2OptionData {
  id: string;
  text: string;
  disabled?: boolean;
  children?: Array<Select2OptionData>;
  additional?: any;
  selected: boolean;
}