import { PermsTypes } from 'src/app/shared/model/AppConst';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { checkForPermission } from '../model/AppConst';

@Directive({
  selector: '[appNeedsPermission]'
})

export class NeedsPermissionDirective {
  @Input('appNeedsPermission') permission: string;

  constructor(public el: ElementRef) {
    setTimeout(() => this.permission && this.checkPermission(this.permission), 200);
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.checkPermission(this.permission);
  }

  private checkPermission(name: string) {
    const hasPerm = checkForPermission(name);

    this.el.nativeElement.disabled = !hasPerm;
    this.el.nativeElement.title = !hasPerm ? 'Você não tem permissão' : '';
  }
}
