import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-tag',
  templateUrl: './input-tag.component.html',
  styleUrls: ['./input-tag.component.scss']
})
export class InputTagComponent implements OnInit {
  @Output() sendTags = new EventEmitter();
  @Input() tags: string[];

  constructor() {
      this.tags = this.tags || [];
  }

  ngOnInit() {
  }

  addTags(ev) {
      if (ev.key === 'Enter' && !!ev.srcElement.value && ev.srcElement.value.split('').some(x => x !== ' ') &&
          !this.tags.some(x => x === ev.srcElement.value)) {
          this.tags.push(ev.srcElement.value);
          ev.srcElement.value = '';
      }
  }

  removeTag(index) {
      this.tags.splice(index, 1);
  }

  saveTags() {
      this.sendTags.emit(this.tags.join(','));
  }
}
