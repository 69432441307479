import { PointFactorComponent } from './point-factor/point-factor.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from './permission.guard';
import { LoginComponent } from './login/login.component';
import { ReportsComponent } from './reports/reports.component';
import { RelatorioComponent } from './relatorios/relatorio.component';
import { VoucherListComponent } from './voucher/voucher-list/voucher-list.componet';
import { BlacklistComponent } from './blacklist/blacklist.componet';
import { PointFactorCoffeeComponent } from './point-factor-coffee/point-factor-coffee.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/homepage',
    pathMatch: 'full',
    canActivate: [PermissionGuard]
  },
  {
    path: 'homepage',
    loadChildren: './homepage/homepage.module#HomepageModule',
    canActivate: [PermissionGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [PermissionGuard]
  },
  {
    path: 'PasswordRecovery',
    loadChildren:
      './password-recovery/password-recovery.module#PasswordRecoveryModule',
    canActivate: [PermissionGuard]
  },
  {
    path: 'users',
    loadChildren: './users/users.module#UsersModule',
    canActivate: [PermissionGuard]
  },
  {
    path: 'promoCampaign',
    loadChildren: './promo-campaign/promo-campaign.module#PromoCampaignModule',
    canActivate: [PermissionGuard]
  },
  {
    path: 'promoRescue',
    loadChildren: './promo-rescue/promo-rescue.module#PromoRescueModule',
    canActivate: [PermissionGuard]
  },
  {
    path: 'promoPdv',
    loadChildren: './promo-pdv/promo-pdv.module#PromoPdvModule',
    canActivate: [PermissionGuard]
  },
  {
    path: 'promoEcommerce',
    loadChildren: './promo-ecommerce/promo-ecommerce.module#PromoEcommerceModule',
    canActivate: [PermissionGuard]
  },
  {
    path: 'permissionGroup',
    loadChildren:
      './permission-group/permission-group.module#PermissionGroupModule',
    canActivate: [PermissionGuard]
  },
  {
    path: 'store',
    loadChildren: './store/store.module#StoreModule',
    canActivate: [PermissionGuard]
  },
  {
    path: 'products',
    loadChildren: './products/products.module#ProductModule',
    canActivate: [PermissionGuard]
  },
  {
    path: 'pointFactor',
    component: PointFactorComponent,
    canActivate: [PermissionGuard]
  },
  {
    path: 'pointFactorCoffee',
    component: PointFactorCoffeeComponent,
    canActivate: [PermissionGuard]
  },
  {
    path: 'sac',
    loadChildren: './sac/sac.module#SacModule',
    canActivate: [PermissionGuard]
  },
  {
    path: 'clusterConfiguration',
    loadChildren: './cluster-configuration/cluster-configuration.module#ClusterConfigurationModule',
    canActivate: [PermissionGuard]
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [PermissionGuard]
  },
  {
    path: 'relatorios',
    component: RelatorioComponent,
    canActivate: [PermissionGuard]
  },
  {
    path: 'voucher',
    component: VoucherListComponent,
    canActivate: [PermissionGuard]
  },
  {
    path: 'blacklist',
    component: BlacklistComponent,
    // canActivate: [PermissionGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule {}
